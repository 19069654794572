import axios from 'axios'

const { REACT_APP_API_GATEWAY } = process.env

export const phoneValidation = (
  mobilePhoneCountryCode: string,
  mobilePhoneNumber: string
) => {
  return axios
    .post(`${REACT_APP_API_GATEWAY}/phone-validation`, {
      mobilePhoneCountryCode,
      mobilePhoneNumber,
    })
    .then((response) => response.data.isValid)
}
