import { ContentSection, Paragraph } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export type TransactionsConfirmedPageProps = {
  storefrontData: any
}

const TransactionsCanceledPage: FunctionComponent<
  TransactionsConfirmedPageProps
> = (props) => {
  const { storefrontData } = props
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOriginSystem = () => storefrontData?.salesChannel?.originSystem

  return (
    <ContentSection pageWrap>
      <div className="u-text-center">
        <h1>
          <i className="c-icon c-icon--[semantic-close] u-text-alert u-giga" />
        </h1>
        <h2>{t(`transactions:${getOriginSystem()}:canceled:title`)}</h2>
      </div>
      <Paragraph>
        {t(`transactions:${getOriginSystem()}:canceled:subtitle:text`)}
      </Paragraph>
      {i18n.exists(
        `transactions:${getOriginSystem()}:canceled:subtitle:email`
      ) &&
        i18n.exists(
          `transactions:${getOriginSystem()}:canceled:subtitle:phone`
        ) && (
          <Paragraph>
            <span>
              {t(`transactions:${getOriginSystem()}:canceled:subtitle:email`, {
                email: storefrontData?.customerData?.contactData?.email,
              })}
            </span>
            <br />
            <span>
              {t(`transactions:${getOriginSystem()}:canceled:subtitle:phone`, {
                phone: `${storefrontData?.customerData?.contactData?.mobilePhoneCountryCode} ${storefrontData?.customerData?.contactData?.mobilePhoneNumber}`,
              })}
            </span>
          </Paragraph>
        )}
    </ContentSection>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(TransactionsCanceledPage)
