export const getBrandName = () => {
  return process.env.REACT_APP_WEBSITE_BRAND
}
export const CONNECTION_TIMEOUT = 30000

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  UNSUPPORTED: 'UNSUPPORTED',
  TOO_BIG: 'TOO_BIG',
  ERROR: 'ERROR',
}
export const MAX_FILESIZE = 1000 * 1000 * 4

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_SEPARATOR = DATE_FORMAT.split('').find((item) => {
  return !item.match(/^[a-zA-Z]*$/)
})
export const NUMBER_FORMAT = 'pt'
export const CURRENCY = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const DECIMAL_SEPARATOR = ','
export const THOUSANDS_SEPARATOR = '.'

export const bronsonConfig = {
  audi: {
    theme: 'audi',
    version: '15.7.1',
  },
  vw: {
    theme: 'vw6',
    version: '15.7.1',
  },
  vwcv: {
    theme: 'vw6',
    version: '15.7.1',
  },
  vwn: {
    theme: 'vw6',
    version: '15.7.1',
  },
  seat: {
    theme: 'seat',
    version: '15.7.1',
  },
  skoda: {
    theme: 'skoda',
    version: '15.7.1',
  },
  vwfs: {
    theme: 'bluelabel',
    version: '15.7.1',
  },
  cupra: {
    theme: 'cupra',
    version: '15.7.1',
  },
}
