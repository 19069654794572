import { CustomState } from '../models/CustomState'
import {
  CustomActions,
  SET_TLS_VALID,
  SET_ERROR_DATA,
  SET_DISCOUNT,
  SET_SERVICE_AND_INSPECTION,
} from './actions/custom.actions'

export function setTlsValid(data): CustomActions {
  return {
    type: SET_TLS_VALID,
    payload: data,
  }
}

export function getTlsValid(store) {
  return store.custom.data.tlsValid
}

export function setErrorData(data): CustomActions {
  return {
    type: SET_ERROR_DATA,
    payload: data,
  }
}

export function getErrorData(store) {
  return store.custom.data.errorData
}

export function setServiceAndInspection(data): CustomActions {
  return {
    type: SET_SERVICE_AND_INSPECTION,
    payload: data,
  }
}

export function getServiceAndInspection(store) {
  return store.custom.data.serviceAndInspection
}

const initialState: CustomState = {
  data: {
    tlsValid: false,
    errorData: {},
    discount: undefined,
    serviceAndInspection: false,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TLS_VALID:
      return {
        ...state,
        data: { ...state.data, tlsValid: action.payload },
      }
    case SET_ERROR_DATA:
      return {
        ...state,
        data: { ...state.data, errorData: action.payload },
      }
    case SET_DISCOUNT:
      return {
        ...state,
        data: { ...state.data, discount: action.payload },
      }
    case SET_SERVICE_AND_INSPECTION:
      return {
        ...state,
        data: { ...state.data, serviceAndInspection: action.payload },
      }
    default:
      return state
  }
}
