export const contractSituations = [
  { value: 'Efetivo', label: 'Efetivo' },
  { value: 'Contrato a Prazo', label: 'Contrato a Prazo' },
  { value: 'Temporário', label: 'Temporário' },
  { value: 'Conta Própria', label: 'Conta Própria' },
  { value: 'Reformado', label: 'Reformado' },
  { value: 'Não comprovado', label: 'Não comprovado' },
  { value: 'Desempregado', label: 'Desempregado' },
  { value: 'S/Informação', label: 'S/Informação' },
  { value: 'Outros', label: 'Outros' },
]
