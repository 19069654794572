import { SessionState } from '../models/SessionState'
import {
  SessionActions,
  SET,
  SET_TOKEN_EXPIRES_AT,
} from './actions/session.actions'

export function setSession(data): SessionActions {
  return {
    type: SET,
    payload: data,
  }
}

export function getSession(store) {
  return store.session.data
}

export function setTokenExpiresAt(data) {
  return {
    type: SET_TOKEN_EXPIRES_AT,
    payload: {
      data,
    },
  }
}

export function getTokenExpiresAt(store) {
  return store.session.tokenExpiresAt
}

const initialState: SessionState = {
  data: { pin: '' },
  tokenExpiresAt: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: action.payload,
      }
    case SET_TOKEN_EXPIRES_AT:
      return {
        ...state,
        tokenExpiresAt: action.payload.data,
      }

    default:
      return state
  }
}
