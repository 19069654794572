import React, { FunctionComponent } from 'react'
import {
  FoldOut,
  ProductInfo,
  ProductInfoSummary,
  FloatingBar,
} from '@vwfs-bronson/bronson-react'
import { ShoppingCartModel } from './ShoppingCartModel'
import { buildVin } from './common'

const scrollRef: any = React.createRef()

const ShoppingCart: FunctionComponent<ShoppingCartModel> = ({
  id,
  defaultOpen = false,
  foldout,
  floatingBar,
  imgSrc,
  imgSrcSet,
  imgSizes,
  title,
  titleSuffix,
  subtitle,
  infoLabel,
  infoValue,
  children,
  subtitleImg,
  vin,
}: ShoppingCartModel) => {
  return (
    <>
      <div ref={scrollRef}></div>
      {foldout ? (
        <FoldOut
          id={id}
          productInfoSummary={
            <ProductInfoSummary
              imgSrc={imgSrc}
              imgSrcSet={imgSrcSet}
              imgSizes={imgSizes}
              imgAlt={title}
              title={title}
              subtitle={subtitle}
              infoLabel={infoLabel}
              infoValue={infoValue}
            />
          }
          defaultOpen={defaultOpen}
        >
          <h3 className="c-fold-out__main-title">{title}</h3>
          {buildVin(vin || '')}
          <ProductInfo
            titleSuffix={titleSuffix}
            media={<img src={imgSrc} alt="" />}
            caption={subtitleImg}
          >
            {children}
          </ProductInfo>
        </FoldOut>
      ) : (
        <ProductInfo
          title={title}
          titleSuffix={titleSuffix}
          media={<img src={imgSrc} alt="" />}
          caption={subtitleImg}
        >
          {children}
        </ProductInfo>
      )}
      {floatingBar ? (
        <FloatingBar target={scrollRef}>
          <ProductInfoSummary
            imgSrc={imgSrc}
            imgAlt={title}
            title={title}
            subtitle={subtitle}
            infoLabel={infoLabel}
            infoValue={infoValue}
          />
        </FloatingBar>
      ) : null}
    </>
  )
}

export { ShoppingCart }
