import { createBrowserHistory } from 'history'
import { store } from '../redux/index'
import * as actions from '../redux/features/custom.redux'
import routes from '../../routes'

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})

// TODO: https://caniuse.com/urlsearchparams, I expect you still need to support IE11 otherwise use URLSearchParams
function qs(queryString, parameter) {
  const strToMap = queryString
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [name, ...value] = item.split('=')
      return {
        ...acc,
        [name]: value.join('='),
      }
    }, {})
  return strToMap[parameter] === undefined ? '' : strToMap[parameter]
}
history.pushPreservePath = (newPath) => {
  if (newPath === routes.errorPage) {
    store.dispatch(actions.setErrorData({ url: window.location.href }))
  }
  history.push(newPath)
}
export { history, qs }
