import React from 'react'
import { Formik, Form, FastField, ErrorMessage } from 'formik'
import {
  Fieldset,
  Card,
  ErrorMessage as BronsonErrorMessage,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import * as Yup from 'yup'
import { CheckboxField } from '../../../components/CheckboxField'
import { connect } from 'react-redux'

const VehicleConditionDealerShop = (props, ref) => {
  const {
    hasSubmited,
    setVehicleConditionValues,
    setIsDirty: setIsDirtyParent,
    storefrontData,
  } = props
  const [isDirty, setIsDirty] = React.useState(false)
  const formState = React.useRef(undefined as any)

  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      return formState.current.isValid
    },
  }))

  React.useEffect(() => {
    setIsDirtyParent(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  React.useEffect(() => {
    if (hasSubmited) {
      setTouchedForm(formState.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubmited])

  const onChangeForm = async (e, formProps) => {
    const { target } = e
    const { name } = target
    if (name) {
      const value = target.type === 'checkbox' ? target.checked : target.value
      await formProps.setFieldValue(name, value)
      await formProps.setFieldTouched(name)
      await setVehicleConditionValues({ ...formProps.values, [name]: value })
    }
  }
  const setTouchedForm = (formProps) => {
    Object.keys(formProps.values).forEach((field) => {
      formProps.setFieldTouched(field)
    })
  }

  const ValidationSchema = Yup.object().shape({
    acceptVehicleDefects: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
    acceptUpdateObligation: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
  })

  const { t } = useTranslation()
  return (
    <Formik
      innerRef={formState}
      initialValues={{
        acceptVehicleDefects: false,
        acceptUpdateObligation: false,
      }}
      validationSchema={ValidationSchema}
      validateOnMount
      onSubmit={() => {}}
      render={(formProps) => {
        setIsDirty(formProps.dirty)
        submitFunction = formProps.submitForm
        return (
          <Form
            id="vehicle-condition-form"
            onChange={(e) => onChangeForm(e, formProps)}
          >
            <Card className="u-text-left">
              <h4>{t('form:vehicleCondition:title')}</h4>
              <Paragraph>
                {t('form:vehicleCondition:DEALERSHOP:deviationDescription')}
                <br />
                <span>
                  <strong>
                    {storefrontData?.vehicleData?.deviationDescription}
                  </strong>
                </span>
              </Paragraph>
              {storefrontData?.vehicleData?.damageDescription && (
                <Paragraph>
                  {t('form:vehicleCondition:DEALERSHOP:damageDescription')}
                  <br />
                  <span>
                    <strong>
                      {storefrontData?.vehicleData?.damageDescription}
                    </strong>
                  </span>
                </Paragraph>
              )}

              <Fieldset className="u-mt">
                <Fieldset.Row>
                  <FastField
                    name="acceptVehicleDefects"
                    label={t(
                      'form:vehicleCondition:DEALERSHOP:acceptVehicleDefects',
                      { companyName: storefrontData?.dealerData?.companyName }
                    )}
                    component={CheckboxField}
                  />
                  <ErrorMessage
                    name="acceptVehicleDefects"
                    component={BronsonErrorMessage}
                  />
                </Fieldset.Row>
                <Fieldset.Row>
                  <FastField
                    name="acceptUpdateObligation"
                    label={t(
                      'form:vehicleCondition:DEALERSHOP:acceptUpdateObligation',
                      { companyName: storefrontData?.dealerData?.companyName }
                    )}
                    component={CheckboxField}
                  />
                  <ErrorMessage
                    name="acceptUpdateObligation"
                    component={BronsonErrorMessage}
                  />
                </Fieldset.Row>
              </Fieldset>
            </Card>
          </Form>
        )
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {}
}

// export default VehicleCondition
export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(React.forwardRef(VehicleConditionDealerShop))
