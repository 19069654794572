import React from 'react'
import i18n from 'i18next'

export const buildVin = (vin: string) => (
  <div>
    {i18n.t('form:shoppingCart:subtitle:vin', {
      vin: vin || '-',
    })}
  </div>
)
