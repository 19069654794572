import i18n from 'i18next'
import * as Yup from 'yup'
import { DATE_FORMAT, DATE_SEPARATOR } from '../../../config'
import { LegalFormType } from '../../../models/enums'

const regExText = /^[^\s][\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß.'-]*$/
const regExTextZipCode = /^[^\s][0-9a-zA-Z-]*$/
const regExTextStreet =
  /^[^\s][0-9\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß.'-ß|/()`´'-._]*$/
const regExEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,61}))$/
const regExNumber = /^[0-9]*$/
const regExTextPlace =
  /^[^\s|(|)][\sa-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšß.'\-()]*$/
const regExTextStreetNumber = /^[^\s][0-9\sa-zA-ZäöüÄÖÜ'-ß|/()`´'-._]*$/

const customerDataShape = (isVtiGis) => {
  return {
    firstName: Yup.string()
      .matches(regExText, i18n.t('validation:invalidCharacter'))
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .required(i18n.t('validation:required')),
    lastName: Yup.string()
      .matches(regExText, i18n.t('validation:invalidCharacter'))
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .required(i18n.t('validation:required')),
    address: Yup.object().shape({
      street: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextStreet, i18n.t('validation:invalidCharacter'))
        .min(2, i18n.t('validation:minLength', { value: 2 }))
        .max(60, i18n.t('validation:maxLength', { value: 60 })),
      houseNumber: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextStreetNumber, i18n.t('validation:invalidCharacter'))
        .min(1, i18n.t('validation:minLength', { value: 1 }))
        .max(10, i18n.t('validation:maxLength', { value: 10 })),
      zipCode: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextZipCode, i18n.t('validation:invalidCharacter'))
        .min(3, i18n.t('validation:minLength', { value: 3 }))
        .max(10, i18n.t('validation:maxLength', { value: 10 })),
      city: Yup.string()
        .required(i18n.t('validation:required'))
        .matches(regExTextPlace, i18n.t('validation:invalidCharacter'))
        .min(2, i18n.t('validation:minLength', { value: 2 }))
        .max(35, i18n.t('validation:maxLength', { value: 35 })),
      countryCode: Yup.string().test(
        'my-test-name',
        i18n.t('validation:required'),
        (value) => value !== 'NONE' && !isVtiGis
      ),
    }),

    contactData: Yup.object().shape({
      email: Yup.string()
        .matches(regExEmail, i18n.t('validation:invalidEmail'))
        .required(i18n.t('validation:required')),
      mobilePhoneCountryCode: Yup.string().required(
        i18n.t('validation:required')
      ),
    }),
  }
}

export const ValidationSchema = Yup.object().shape({
  customerData: Yup.object().shape(customerDataShape(false)),
})

export const ValidationSchemaBusiness = ValidationSchema.shape({
  companyData: Yup.object().shape({
    entityLegalForm: Yup.string()
      .required(i18n.t('validation:required'))
      .oneOf(
        [
          LegalFormType.SOLE_TRADER,
          LegalFormType.REGISTERED_SOLE_TRADER,
          LegalFormType.FREELANCER,
        ],
        i18n.t('validation:required')
      ),
    name: Yup.string()
      .matches(regExText, i18n.t('validation:invalidCharacter'))
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .required(i18n.t('validation:required')),
  }),
})
export const ValidationSchemaPrivate = (isVtiGis) =>
  ValidationSchema.shape({
    customerData: Yup.object().shape({
      ...customerDataShape(isVtiGis),
      dateOfBirth: Yup.string()
        .formatOfDate(
          DATE_FORMAT,
          DATE_SEPARATOR,
          i18n.t('validation:invalidDateformat')
        )
        .notInFuture(DATE_SEPARATOR, i18n.t('validation:dateInFuture'))
        .fullAge(18, DATE_SEPARATOR, i18n.t('validation:fullAge'))
        .required(i18n.t('validation:required')),
    }),
  })

export const mobilePhoneNumberSchema = Yup.object().shape({
  mobilePhoneNumber: Yup.string()
    .required(i18n.t('validation:required'))
    .matches(regExNumber, i18n.t('validation:invalidCharacter'))
    .min(3, i18n.t('validation:minLength', { value: 3 }))
    .max(15, i18n.t('validation:maxLength', { value: 15 }))
    .validatePhone(i18n.t('validation:invalidPhone')),
})
