import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { trackPage } from './tracking'
import { isDealershop, isVaFa, isVtiGiS } from '../../services/common/utils'
import ConfirmationPageVTIGIS from './ConfirmationPageVTIGIS'
import ConfirmationPageVAFA from './ConfirmationPageVAFA'
import ConfirmationPageDEALERSHOP from './ConfirmationPageDEALERSHOP'

export type ConfirmationPageProps = {
  originSystem: string
  storefrontData: any
}

const ConfirmationPage: FunctionComponent<ConfirmationPageProps> = (props) => {
  const { storefrontData } = props
  const { t } = useTranslation()
  React.useEffect(() => {
    document.title = `${t('confirmation:tabTitle')}`
  }, [t])

  React.useEffect(() => {
    trackPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isDealershop(storefrontData) && (
        <ConfirmationPageDEALERSHOP storefrontData={storefrontData} />
      )}
      {isVaFa(storefrontData) && (
        <ConfirmationPageVAFA storefrontData={storefrontData} />
      )}
      {isVtiGiS(storefrontData) && (
        <ConfirmationPageVTIGIS storefrontData={storefrontData} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(ConfirmationPage)
