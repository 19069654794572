import React, { useMemo } from 'react'
import {
  Card,
  ContentSection,
  FormSection,
  InfoIcon,
  Layout,
  Paragraph,
  TileSelect,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../../../services/common/format'
import { isVtiGiS } from '../../../services/common/utils'
import {
  getServiceAndInspection,
  setServiceAndInspection,
} from '../../../services/redux/features/custom.redux'

const AdditionalProducts = (props) => {
  const { t } = useTranslation()
  const {
    onSelection,
    serviceAndInspection,
    setServiceAndInspection: setServiceAndInspectionAction,
    storefrontData,
  } = props

  const additionalProducts = useMemo(
    () => storefrontData?.financialProduct?.additionalProducts,
    [storefrontData]
  )

  const shouldShowAdditionalProducts =
    isVtiGiS(storefrontData) &&
    additionalProducts?.find((product) => product.isVisible)
  return (
    <>
      <ContentSection pageWrap className="u-pt-small u-pb-small">
        <ContentSection.ComponentWrapper>
          <Card>
            <h4 className="u-text-left">{t('additionalProducts:title')}</h4>
            {(
              t('additionalProducts:texts', {
                returnObjects: true,
              }) as Array<string>
            )?.map((text: string) => (
              <Paragraph className="u-text-left" key={text}>
                {text}
              </Paragraph>
            ))}
            <Layout center equalHeight>
              <Layout.Item default="1/3" s="1/1">
                <TileSelect
                  id="serviceAndInspection"
                  title={t('additionalProducts:serviceAndInspection.title')}
                  checked={serviceAndInspection}
                  disabled={!storefrontData?.serviceInspectionRate}
                  onClick={() => {
                    setServiceAndInspectionAction(!serviceAndInspection)
                  }}
                >
                  {storefrontData?.serviceInspectionRate ?
                    <>
                      <Paragraph>
                        {formatPrice(
                          storefrontData?.serviceInspectionRate?.prices?.[0]
                            ?.grossLoanAmount?.amount || 0
                        )}
                        {t('additionalProducts:serviceAndInspection.text1')}
                        <InfoIcon icon="semantic-info">
                          {t('additionalProducts:serviceAndInspection.tooltip')}
                        </InfoIcon>
                      </Paragraph>
                      <Paragraph>
                        {t('additionalProducts:serviceAndInspection.text2')}
                      </Paragraph>
                    </>
                    : <Paragraph>
                      {t('additionalProducts:serviceAndInspection.disabled')}
                    </Paragraph>
                  }
                </TileSelect>
              </Layout.Item>
              {!!shouldShowAdditionalProducts &&
                additionalProducts?.map((product: any) =>
                  product?.isVisible ? (
                    <Layout.Item default="1/3" s="1/1" key={product?.name}>
                      <TileSelect
                        id={product?.name}
                        title={product?.name}
                        onClick={() => onSelection(product)}
                        checked={product?.isSelected}
                        disabled={!product?.isSelectable}
                      >
                        <Paragraph>{product?.description}</Paragraph>
                        <Paragraph>{product?.disclaimer}</Paragraph>
                      </TileSelect>
                    </Layout.Item>
                  ) : (
                    <></>
                  )
                )}
            </Layout>
          </Card>
        </ContentSection.ComponentWrapper>
      </ContentSection>
      <ContentSection pageWrap className="u-pt-small u-pb-small">
        <ContentSection.ComponentWrapper>
          <FormSection title={t('additionalProducts:accordion.title')}>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('additionalProducts:accordion.text1'),
                }}
              ></span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={t('additionalProducts:accordion.linkHref')}
              >
                {t('additionalProducts:accordion.linkText')}
              </a>
              <br />
              {t('additionalProducts:accordion.text2')}
            </Paragraph>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: t('additionalProducts:accordion.text3'),
              }}
            />
          </FormSection>
        </ContentSection.ComponentWrapper>
      </ContentSection>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
    serviceAndInspection: getServiceAndInspection(state),
  }
}

export default connect(mapStateToProps, {
  setServiceAndInspection,
})(AdditionalProducts)
