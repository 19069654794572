/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo, useState } from 'react'
import {
  Modal,
  Paragraph,
  ResponsiveImage,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import ENVKVShoppingCartCaptionModal from './ENVKVShoppingCartCaptionModal'
import { getEnvkvImage } from './api/api'
import { useTranslation } from 'react-i18next'
import { MimeType } from '../../models/enums'
import { openTabUrl } from '../../services/common/utils'

export type DescriptionListGroupProps = {
  onError?: any
  transactionId: string
  vehicleData: any
}

const ENVKVShoppingCartCaption: FunctionComponent<DescriptionListGroupProps> = (
  props
) => {
  const { t } = useTranslation()
  const { onError, transactionId, vehicleData } = props
  const economics = useMemo(() => vehicleData?.model?.economics, [vehicleData])
  const [showImageModal, setShowImageModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [envkvImage, setEnvkvImage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const onLinkClick = async () => {
    if (economics?.[0]?.emissionLabel?.file) {
      try {
        setIsLoading(true)
        const file = await getEnvkvImage(transactionId)
        if (file?.mimeType === MimeType.SVG) {
          setEnvkvImage(file?.url)
          setShowImageModal(true)
        } else {
          openTabUrl(file?.url)
        }
      } catch {
        if (onError) {
          onError()
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      {isLoading && <Spinner center fullPage />}
      {showImageModal && (
        <Modal
          stretch
          shown={showImageModal}
          onClose={() => setShowImageModal(false)}
        >
          <ResponsiveImage src={envkvImage} />
        </Modal>
      )}
      {showModal && (
        <ENVKVShoppingCartCaptionModal
          vehicleData={vehicleData}
          shown={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      <Paragraph>
        <a onClick={onLinkClick} id="economics-hyperlink">
          {t('form:shoppingCart.imageFooter.ENVKV.hyperLink')}
        </a>
      </Paragraph>
      <Paragraph
        key={t('form:shoppingCart.imageFooter.ENVKV.suffix')}
        dangerouslySetInnerHTML={{
          __html: t('form:shoppingCart.imageFooter.ENVKV.suffix'),
        }}
      />
    </>
  )
}

export default ENVKVShoppingCartCaption
