import React from 'react'
import {
  ContentSection,
  Fieldset,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import {
  getOriginSystem,
  isDealershop,
  isVaFa,
} from '../../../services/common/utils'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const SummaryVehicleSectionSuffix = (props) => {
  const { storefrontData } = props
  const { t } = useTranslation()

  return (
    <>
      {isDealershop(storefrontData) && (
        <ContentSection.ComponentWrapper className="u-mt">
          <Paragraph>
            {t(`summaryVehicle:${getOriginSystem(storefrontData)}:suffix:1`)}
          </Paragraph>
          <Paragraph>
            <span>
              {t(
                `summaryVehicle:${getOriginSystem(
                  storefrontData
                )}:suffix:2:text1`
              )}
              <a
                href={storefrontData?.dealerData?.cancellationPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  `summaryVehicle:${getOriginSystem(
                    storefrontData
                  )}:suffix:2:link:text`
                )}
              </a>
              {t(
                `summaryVehicle:${getOriginSystem(
                  storefrontData
                )}:suffix:2:text2`
              )}
            </span>
          </Paragraph>
          <Paragraph className="u-mb-none">
            {t(
              `summaryVehicle:${getOriginSystem(storefrontData)}:suffix:dealer`
            )}
            <br />
            {storefrontData?.dealerData?.companyName}
            <br />
            {`${storefrontData?.dealerData?.address?.street} ${storefrontData?.dealerData?.address?.houseNumber}`}
            <br />
            {`${storefrontData?.dealerData?.address?.zipCode} ${storefrontData?.dealerData?.address?.city}`}
          </Paragraph>
        </ContentSection.ComponentWrapper>
      )}
      {isVaFa(storefrontData) && (
        <ContentSection.ComponentWrapper className="u-mt">
          <Fieldset.Row>
            <span
              dangerouslySetInnerHTML={{
                __html: t('summaryVehicle:additionalFooterNote'),
              }}
            />
          </Fieldset.Row>
        </ContentSection.ComponentWrapper>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(SummaryVehicleSectionSuffix)
