export const occupations = [
  { value: 'Administrativo', label: 'Administrativo' },
  { value: 'Admins./Quadro Superior', label: 'Admins./Quadro Superior' },
  { value: 'Advogado', label: 'Advogado' },
  { value: 'Agricultor/Pescador', label: 'Agricultor/Pescador' },
  { value: 'Arquitecto', label: 'Arquitecto' },
  { value: 'Bancário', label: 'Bancário' },
  { value: 'Comissionista', label: 'Comissionista' },
  { value: 'Construção Civil', label: 'Construção Civil' },
  { value: 'Contabilista', label: 'Contabilista' },
  { value: 'Desempregado', label: 'Desempregado' },
  { value: 'Despachante', label: 'Despachante' },
  { value: 'Diplomata', label: 'Diplomata' },
  { value: 'Director', label: 'Director' },
  { value: 'Director/Manager', label: 'Director/Manager' },
  { value: 'Doméstica', label: 'Doméstica' },
  { value: 'Dona de casa', label: 'Dona de casa' },
  { value: 'Economista ', label: 'Economista ' },
  { value: 'Emigrante', label: 'Emigrante' },
  { value: 'Empregado Comércio', label: 'Empregado Comércio' },
  { value: 'Empresário', label: 'Empresário' },
  { value: 'Empresário Diversos', label: 'Empresário Diversos' },
  { value: 'Encarregado', label: 'Encarregado' },
  { value: 'Engenheiro', label: 'Engenheiro' },
  { value: 'Enfermeiro', label: 'Enfermeiro' },
  { value: 'ENI', label: 'ENI' },
  { value: 'Estagiário', label: 'Estagiário' },
  { value: 'Estudante', label: 'Estudante' },
  { value: 'Farmacêutico', label: 'Farmacêutico' },
  { value: 'Feirante', label: 'Feirante' },
  { value: 'Força Aérea', label: 'Força Aérea' },
  { value: 'Função Publica', label: 'Função Publica' },
  { value: 'Fut. Profissional', label: 'Fut. Profissional' },
  { value: 'Futebolista', label: 'Futebolista' },
  { value: 'Gerente', label: 'Gerente' },
  { value: 'Informático', label: 'Informático' },
  { value: 'Jornalista', label: 'Jornalista' },
  { value: 'Juiz', label: 'Juiz' },
  { value: 'Mecânico', label: 'Mecânico' },
  { value: 'Médico', label: 'Médico' },
  { value: 'Militar', label: 'Militar' },
  { value: 'Militar Oficial', label: 'Militar Oficial' },
  { value: 'Motorista', label: 'Motorista' },
  { value: 'Motorista de Táxi', label: 'Motorista de Táxi' },
  { value: 'Não disponível', label: 'Não disponível' },
  { value: 'Operário Especializado', label: 'Operário Especializado' },
  { value: 'Operário Não Especializado', label: 'Operário Não Especializado' },
  { value: 'Outros', label: 'Outros' },
  { value: 'Piloto', label: 'Piloto' },
  { value: 'Piloto de Aviação', label: 'Piloto de Aviação' },
  { value: 'Policia/GNR', label: 'Policia/GNR' },
  { value: 'Politico', label: 'Politico' },
  { value: 'Professor Universitário', label: 'Professor Universitário' },
  { value: 'Professor', label: 'Professor' },
  { value: 'Quadro Médio', label: 'Quadro Médio' },
  { value: 'Quadro Superior', label: 'Quadro Superior' },
  { value: 'Reformado', label: 'Reformado' },
  { value: 'Reformado/Pensionista', label: 'Reformado/Pensionista' },
  { value: 'ROC', label: 'ROC' },
  { value: 'Segurança', label: 'Segurança' },
  { value: 'Sócio-Gerente', label: 'Sócio-Gerente' },
  { value: 'Técnico', label: 'Técnico' },
  { value: 'Trabalho Temporário', label: 'Trabalho Temporário' },
  { value: 'Vendedor', label: 'Vendedor' },
  { value: 'Vive de Rendimentos', label: 'Vive de Rendimentos' },
]
