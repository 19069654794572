export const SET = 'session//SET'
export const SET_TOKEN_EXPIRES_AT = 'session//SET_TOKEN_EXPIRES_AT'

interface SetSessionAction {
  type: typeof SET
  payload: any
}

interface SetTokenExpiresAtAction {
  type: typeof SET_TOKEN_EXPIRES_AT
  payload: any
}

export type SessionActions = SetSessionAction | SetTokenExpiresAtAction
