import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ButtonContainer,
  Fieldset,
  Input,
  Layout,
  Modal,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FormField } from '../FormField'
import { sendTan, tanValidation } from '../../services/api/mailValidation'
import routes from '../../routes'
import { history } from '../../services/routing'
import { setDealerPaymentData } from '../../services/redux/features/storefront.redux'
import { trackEmailValidationModal, trackInvalidCode } from './tracking'
import { saveSesionData } from '../../services/common/utils'

const COOLDOWN_TIMER = 60

function EmailValidationModal({ ...props }) {
  const { t } = useTranslation()
  const { transactionId, visible, onClose, onVerify, email } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [counter, setCounter] = React.useState(0)

  let setFormErrors

  const initialValues = {
    code: '',
  }

  const ValidationSchema = Yup.object().shape({
    code: Yup.string().required(t('validation:required')),
  })

  const newTan = async () => {
    try {
      setIsLoading(true)
      const response = await sendTan(transactionId)
      saveSesionData({
        transactionId: transactionId,
        token: response?.data?.token,
      })
      resetCounter()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      history.pushPreservePath(routes.errorPage)
    }
  }

  const handleErrorSubmit = (error) => {
    const status = error?.response?.status?.toString()
    switch (status) {
      case '400':
        setFormErrors({ code: t('validation:invalidTan') })
        break
      case '409':
        history.push(routes.vehicleNotAvailablePage)
        break
      default:
        history.push(routes.errorPage)
    }
  }

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      const response = await tanValidation(values?.code, transactionId)
      setIsLoading(false)
      props.setDealerPaymentData(response?.data?.dealerData?.paymentData)
      history.pushPreservePath(routes.confirmationPage)
    } catch (error) {
      setIsLoading(false)
      trackInvalidCode()
      handleErrorSubmit(error)
    }
  }

  const resetCounter = async () => {
    setCounter(COOLDOWN_TIMER)
  }

  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(async () => {
        await setCounter(counter - 1)
      }, 1000)
    }
  }, [counter])

  React.useEffect(() => {
    if (visible) {
      trackEmailValidationModal()
    }
  }, [visible])

  const buildCounter = () => {
    return `${counter}${t('emailValidationModal:timeUnit')}`
  }

  return (
    <>
      {isLoading && <Spinner center fullPage />}
      <Modal
        title={t('emailValidationModal:title')}
        onClose={() => {
          if (onClose) {
            onClose()
          }
        }}
        shown={visible}
        stretch
      >
        <Layout className="u-mb-large">
          <Layout.Item default="1/1">
            {t('emailValidationModal:text', {
              email,
            })}
          </Layout.Item>
          <Layout.Item default="1/1">
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={(e) => handleSubmit(e)}
              render={(formProps) => {
                setFormErrors = formProps.setErrors
                const handleChangeOwn = (ev) => {
                  if (ev.target.name === 'code') {
                    formProps.setFieldValue(
                      'code',
                      ev.target.value.replace(/\s+/g, '')
                    )
                  }
                }
                return (
                  <Form id="data-protection-form" onChange={handleChangeOwn}>
                    <Fieldset.Row>
                      <Layout center>
                        <Layout.Item default="1/2" className="u-mt">
                          <FormField
                            testId="code"
                            type="input"
                            name="code"
                            labelText={t('emailValidationModal:code')}
                            render={(fieldProps) => <Input {...fieldProps} />}
                          />
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                    <Fieldset.Row className="u-text-center">
                      <Layout center>
                        <Layout.Item default="1/1">
                          <ButtonContainer center>
                            <Button className="u-text-center" type="submit">
                              {t('emailValidationModal:button')}
                            </Button>
                          </ButtonContainer>
                        </Layout.Item>
                        {counter > 0 && (
                          <Layout.Item
                            default="1/1"
                            className="u-mt u-text-center"
                          >
                            {buildCounter()}
                          </Layout.Item>
                        )}
                      </Layout>
                    </Fieldset.Row>
                  </Form>
                )
              }}
            />
          </Layout.Item>
          <Layout.Item default="1/1">
            <Layout>
              <Layout.Item default="1/2" className="u-text-left u-mt-large">
                <Button
                  onClick={() => {
                    if (onVerify) {
                      onVerify()
                    }
                  }}
                  link
                >
                  {t('emailValidationModal:verifyLink')}
                </Button>
              </Layout.Item>
              <Layout.Item default="1/2" className="u-text-right u-mt-large">
                <Button
                  onClick={() => {
                    newTan()
                  }}
                  link
                  disabled={counter > 0}
                >
                  {t('emailValidationModal:resendLink')}
                </Button>
              </Layout.Item>
            </Layout>
          </Layout.Item>
        </Layout>
      </Modal>
    </>
  )
}

const mapStateToProps = () => {}

export default connect(mapStateToProps, {
  setDealerPaymentData,
})(EmailValidationModal)
