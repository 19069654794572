import axios from 'axios'
import { store } from '../../redux'

const { REACT_APP_API_GATEWAY } = process.env

export const completeJourney = (data: any): Promise<any> => {
  const dataStorageId = store.getState().storefront.id
  const url = `${REACT_APP_API_GATEWAY}/complete-form/${dataStorageId}`
  return axios
    .post(url, data)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
