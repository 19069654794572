import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Modal, Paragraph, Spinner } from '@vwfs-bronson/bronson-react'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EXPIRY_COUNTDOWN_TIME } from './ExpiredSessionLogic'
import { renewAuth } from '../../services/api/expiredSession'

type ExpiredSessionModalProps = {
  onClose: any
  onConfirm: any
  shown: boolean
  transactionId: string
}

const ExpiredSessionModal: FunctionComponent<ExpiredSessionModalProps> = (
  props
) => {
  const { t } = useTranslation()
  const { onClose, onConfirm: onConfirmCallback, shown, transactionId } = props
  const [countDown, setCountDown] = useState(EXPIRY_COUNTDOWN_TIME)
  const [isLoading, setIsLoading] = useState(false)
  const timeoutTimer = useRef<ReturnType<typeof setInterval> | null>(null)
  const timer = useRef<ReturnType<typeof setInterval> | null>(null)

  const restartCountDown = async () => {
    await setCountDown(EXPIRY_COUNTDOWN_TIME)
  }

  const finishPoll = () => {
    if (timer.current && timeoutTimer.current) {
      clearInterval(timer.current)
      clearTimeout(timeoutTimer.current)
    }
  }

  const checkPolling = () => {
    const executePoll = () => {
      c -= 1000
      setCountDown(c)
    }
    const timeoutReached = () => {
      finishPoll()
      if (onClose) {
        onClose()
      }
    }

    restartCountDown()
    const TIMEOUT = EXPIRY_COUNTDOWN_TIME
    let c = EXPIRY_COUNTDOWN_TIME
    const WAIT_TIME = 1000
    timeoutTimer.current = setTimeout(() => timeoutReached(), TIMEOUT)
    timer.current = setInterval(executePoll, WAIT_TIME)
  }

  useEffect(() => {
    if (shown) {
      checkPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shown])

  const onConfirm = async () => {
    setIsLoading(true)
    try {
      finishPoll()
      const renewData = await renewAuth(transactionId)
      if (onConfirmCallback) {
        onConfirmCallback(renewData)
      }
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <Spinner fullPage />}
      <Modal
        buttonConfirmText={t('expiredSession:modal:button')}
        onConfirm={onConfirm}
        onClose={() => {
          if (onClose) {
            onClose()
          }
        }}
        shown={shown}
        hideCloseButton
        stretch
      >
        <Paragraph className="u-text-center">
          {t('expiredSession:modal:text', {
            countDown: moment.utc(countDown).format('mm:ss'),
          })}
        </Paragraph>
      </Modal>
    </>
  )
}

export { ExpiredSessionModal }
