import React from 'react'
import {
  ErrorMessage as BronsonErrorMessage,
  Fieldset,
  Layout,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ErrorMessage, FastField } from 'formik'
import { CheckboxField } from '../../../components/CheckboxField'
import { getOriginSystem } from '../../../services/common/utils'

const LimitationPeriod = (props) => {
  const { t } = useTranslation()
  const { storefrontData } = props

  return (
    <>
      <h4 className="u-text-left">
        {t(`form:limitationPeriod:${getOriginSystem(storefrontData)}:title`)}
      </h4>
      {(
        t(`form:limitationPeriod:${getOriginSystem(storefrontData)}:texts`, {
          companyName: storefrontData?.dealerData?.companyName,
          returnObjects: true,
        }) as Array<string>
      )?.map((text: string) => (
        <Paragraph className="u-text-left" key={text}>
          {text}
        </Paragraph>
      ))}
      <Layout>
        <Layout.Item default="1/1">
          <Fieldset.Row className="u-mt-xsmall">
            <FastField
              name={t(
                `form:limitationPeriod:${getOriginSystem(
                  storefrontData
                )}:checkbox:name`
              )}
              label={t(
                `form:limitationPeriod:${getOriginSystem(
                  storefrontData
                )}:checkbox:text`
              )}
              component={CheckboxField}
            />
            <ErrorMessage
              name={t(
                `form:limitationPeriod:${getOriginSystem(
                  storefrontData
                )}:checkbox:name`
              )}
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
        </Layout.Item>
      </Layout>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(React.forwardRef(LimitationPeriod))
