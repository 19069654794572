/* eslint-disable no-nested-ternary */
import React from 'react'
import {
  Card,
  Fieldset,
  ErrorMessage as BronsonErrorMessage,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, FastField } from 'formik'
import { CheckboxField } from '../../../../../components/CheckboxField'
import { connect } from 'react-redux'
import {
  getOriginSystem,
  isBusiness,
  isDealershop,
  isVaFa,
  isVtiGiS,
} from '../../../../../services/common/utils'

const ExplanationComponent = (props) => {
  const { t } = useTranslation()
  const { storefrontData } = props
  return (
    <Card className="u-text-left">
      <Fieldset.Row>
        <h4 className="u-h4">{t('form:dataProtection:title')}</h4>
      </Fieldset.Row>
      {!isDealershop(storefrontData) && (
        <Fieldset.Row>
          <FastField
            name={
              isBusiness(storefrontData)
                ? 'acceptPurchaseConditionsVafa'
                : isVtiGiS(storefrontData)
                ? 'acceptPrecontractualInformationVti'
                : 'acceptPrecontractualInformationVafa'
            }
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    `form:dataProtection:${
                      isBusiness(storefrontData)
                        ? 'business:acceptPurchaseConditionsVafa'
                        : isVtiGiS(storefrontData)
                        ? 'vti:acceptPrecontractualInformationVti'
                        : 'private:acceptPrecontractualInformationVafa'
                    }`,
                    { companyName: storefrontData?.dealerData?.companyName }
                  ),
                }}
              />
            }
            component={CheckboxField}
          />
          <ErrorMessage
            name={
              isBusiness(storefrontData)
                ? 'acceptPurchaseConditionsVafa'
                : isVtiGiS(storefrontData)
                ? 'acceptPrecontractualInformationVti'
                : 'acceptPrecontractualInformationVafa'
            }
            component={BronsonErrorMessage}
          />
        </Fieldset.Row>
      )}
      {isDealershop(storefrontData) && (
        <>
          <Fieldset.Row>
            <FastField
              name="acceptPrecontractualInformationDealershop"
              label={
                <span>
                  {t(
                    `form:dataProtection:${getOriginSystem(
                      storefrontData
                    )}:explanationComponent:acceptPrecontractualInformationDealershop:text1`
                  )}
                  <a
                    href={storefrontData?.dealerData?.preContractInformationUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(
                      `form:dataProtection:${getOriginSystem(
                        storefrontData
                      )}:explanationComponent:acceptPrecontractualInformationDealershop:link`
                    )}
                  </a>
                  {t(
                    `form:dataProtection:${getOriginSystem(
                      storefrontData
                    )}:explanationComponent:acceptPrecontractualInformationDealershop:text2`,
                    {
                      companyName: storefrontData?.dealerData?.companyName,
                    }
                  )}
                </span>
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptPrecontractualInformationDealershop"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row>
            <FastField
              name="acceptSalesTerms"
              label={
                <span>
                  {t(
                    `form:dataProtection:${getOriginSystem(
                      storefrontData
                    )}:explanationComponent:acceptSalesTerms:text1`
                  )}
                  <a
                    href={storefrontData?.dealerData?.salesTermsUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(
                      `form:dataProtection:${getOriginSystem(
                        storefrontData
                      )}:explanationComponent:acceptSalesTerms:link`
                    )}
                  </a>
                  {t(
                    `form:dataProtection:${getOriginSystem(
                      storefrontData
                    )}:explanationComponent:acceptSalesTerms:text2`,
                    {
                      companyName: storefrontData?.dealerData?.companyName,
                    }
                  )}
                </span>
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptSalesTerms"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row>
            <span>
              {t(
                `form:dataProtection:${getOriginSystem(
                  storefrontData
                )}:explanationComponent:suffix:text1`
              )}
              <a
                href={storefrontData?.dealerData?.dataProtectionUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t(
                  `form:dataProtection:${getOriginSystem(
                    storefrontData
                  )}:explanationComponent:suffix:link`
                )}
              </a>
              {t(
                `form:dataProtection:${getOriginSystem(
                  storefrontData
                )}:explanationComponent:suffix:text2`,
                {
                  companyName: storefrontData?.dealerData?.companyName,
                }
              )}
            </span>
          </Fieldset.Row>
        </>
      )}
      {isBusiness(storefrontData) && (
        <Fieldset.Row>
          <FastField
            name="acceptVehicleCondition"
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    `form:dataProtection:business:acceptVehicleCondition`
                  ),
                }}
              />
            }
            component={CheckboxField}
          />
          <ErrorMessage
            name="acceptVehicleCondition"
            component={BronsonErrorMessage}
          />
        </Fieldset.Row>
      )}
      {isVaFa(storefrontData) && (
        <>
          <Fieldset.Row>
            <FastField
              name="acceptVehicleOwnership"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `form:dataProtection:business:acceptVehicleOwnership`
                    ),
                  }}
                />
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptVehicleOwnership"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row>
            <FastField
              name="acceptLeasingTermination"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `form:dataProtection:business:acceptLeasingTermination`
                    ),
                  }}
                />
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptLeasingTermination"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row>
            <FastField
              name="acceptLeasingServices"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `form:dataProtection:business:acceptLeasingServices`
                    ),
                  }}
                />
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptLeasingServices"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
        </>
      )}
      {isVtiGiS(storefrontData) && (
        <>
          <Fieldset.Row>
            <FastField
              name="acceptPurchaseConditionsVti"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `form:dataProtection:vti:acceptPurchaseConditionsVti`
                    ),
                  }}
                />
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptPurchaseConditionsVti"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row>
            <FastField
              name="acceptInsuranceDocuments"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `form:dataProtection:vti:acceptInsuranceDocuments`
                    ),
                  }}
                />
              }
              component={CheckboxField}
            />
            <ErrorMessage
              name="acceptInsuranceDocuments"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
          <Fieldset.Row className="u-mt">
            <h4 className="u-h4">{t('form:dataProtection:conditionsTitle')}</h4>
          </Fieldset.Row>
          <Fieldset.Row>
            <p>{t('form:dataProtection:conditionsTextVti')}</p>
          </Fieldset.Row>{' '}
        </>
      )}
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, null, null)(ExplanationComponent)
