import React, { useEffect, useState, Suspense } from 'react'
import axios from 'axios'
import { Spinner } from '@vwfs-bronson/bronson-react'

interface Package {
  name: string
  version: string
  copyright: string
}

interface License {
  license: string
  licenses: string
  name: string
  displayName: string
  licenseLink: string
  packages: Package[]
  licenseText: string
  repository: string
  publisher: string
}

const ThirdPartyLicensePage = () => {
  const [licenses, setLicenses] = useState<License[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!Object.keys(licenses).length) {
      axios
        .get('/licenses/licenses.json')
        .then(({ data }) => {
          const arrayList = Object.keys(data).map((item) => {
            return { name: item, ...data[item] }
          })
          setIsLoading(false)
          setLicenses(arrayList)
        })
        .catch(() => setIsLoading(false))
    }
  })

  return (
    <Suspense fallback={<Spinner fullPage />}>
      {isLoading && <Spinner fullPage />}
      <div className="o-content-section">
        <div className="o-page-wrap o-page-wrap--medium">
          <div className="u-mb-large">
            <div className="c-table-wrapper u-mv c-table--colored c-table--sticky-head">
              <table>
                <thead>
                  <tr>
                    <th className="c-table__cell--highlight">Komponente</th>
                    <th className="c-table__cell--highlight">Version</th>
                    <th className="c-table__cell--highlight">Publisher</th>
                    <th className="c-table__cell--highlight">Copyright</th>
                  </tr>
                </thead>
                <tbody>
                  {licenses &&
                    licenses.map((license) => {
                      const pattCustom = /^Custom:/g
                      const licenseVersion = license.name.split('@').pop()
                      const licenseName = license.name.replace(
                        `@${licenseVersion}`,
                        ''
                      )
                      const licenseLicense = pattCustom.test(
                        license.licenses
                      ) ? (
                        <a
                          href={license.licenses.replace('Custom: ', '')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Custom
                        </a>
                      ) : (
                        license.licenses
                      )
                      return (
                        <tr key={license.name}>
                          <td className="u-text-fs-2">
                            <a
                              href={license.repository}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {licenseName}
                            </a>
                          </td>
                          <td className="u-text-fs-2">{licenseVersion}</td>
                          <td className="u-text-fs-2">{license.publisher}</td>
                          <td className="u-text-fs-2">{licenseLicense}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default ThirdPartyLicensePage
