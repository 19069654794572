/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'
import {
  DescriptionList,
  Paragraph,
  ProductTable,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import * as lodashGet from 'lodash/get'
import { formatPrice } from '../../services/common/format'
import { ShoppingCart } from './ShoppingCart'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import { getFormData } from '../../services/redux/features/form.redux'
import { getBrandName } from '../../config'
import { RootState } from '../../services/redux'
import {
  getOriginSystem,
  isDealershop,
  isVaFa,
  isVtiGiS,
} from '../../services/common/utils'
import './storefrontShoppingCart.css'
import ShoppingCartImageFooter from './ShoppingCartImageFooter'
import { buildVin } from './common'

const get: any = (object: any, path: string) => {
  return lodashGet(object, path, '-')
}

export type StorefrontShoppingCartProps = {
  storefrontData: any
  foldout: any
  defaultOpen: boolean
  floatingBar: any
  formData: any
  discount: any
}

const StorefrontShoppingCart = (props: StorefrontShoppingCartProps) => {
  const { storefrontData, foldout, floatingBar, defaultOpen = false } = props
  const { t } = useTranslation()
  if (!storefrontData || !storefrontData.vehicleData) return null
  const { vehicleData } = storefrontData
  const imgVehicle = vehicleData.model?.vehicleImageUrl

  let imgVehicleSrcSet = `${imgVehicle} 520vw`

  if (imgVehicle && imgVehicle.indexOf('.img.') !== -1) {
    imgVehicleSrcSet =
      imgVehicle.split('.').pop() === 'jpg'
        ? ['.jpg.s.jpg 350w', '.jpg.m.jpg 520w']
            .map((imgSize) => imgVehicle.replace('.jpg.img.jpg', imgSize))
            .join(',')
        : ['.png.s.png 350w', '.png.m.png 520w']
            .map((imgSize) => imgVehicle.replace('.png.img.png', imgSize))
            .join(',')
  }
  const afterPriceTexts =
    isVaFa(storefrontData) ||
    isVtiGiS(storefrontData) ||
    isDealershop(storefrontData)
      ? (t(
          `form:shoppingCart:afterPriceText:${getOriginSystem(storefrontData)}`,
          {
            returnObjects: true,
            companyName: storefrontData?.dealerData?.companyName,
            address1: `${storefrontData?.dealerData?.address?.street} ${storefrontData?.dealerData?.address?.houseNumber}`,
            address2: `${storefrontData?.dealerData?.address?.zipCode} ${storefrontData?.dealerData?.address?.city}`,
          }
        ) as string[])
      : (t(`form:shoppingCart:afterPriceText:${getBrandName()}`, {
          returnObjects: true,
        }) as string[])
  return (
    <>
      <ShoppingCart
        id="shoppingCart"
        defaultOpen={defaultOpen}
        foldout={foldout}
        floatingBar={floatingBar}
        imgSrc={imgVehicle && imgVehicle}
        imgSrcSet={imgVehicle && imgVehicleSrcSet}
        imgSizes="175px"
        title={`${get(vehicleData, 'model.description')}`}
        infoLabel={t('form:shoppingCart:totalAmount')}
        infoValue={formatPrice(vehicleData?.purchasePriceAmount)}
        subtitle={
          (
            <>
              {buildVin(vehicleData?.vin)}
              <div className="u-mt-xsmall">
                <strong>
                  {t(
                    `form:shoppingCart:subtitle:${
                      !vehicleData?.isUsed &&
                      (vehicleData.odometer < 1000 || !vehicleData.odometer)
                        ? 'text1'
                        : 'text2'
                    }`
                  )}
                </strong>
              </div>
            </>
          ) as unknown as string
        }
        subtitleImg={
          vehicleData?.model?.economics && (
            <ShoppingCartImageFooter vehicleData={vehicleData} />
          )
        }
        vin={vehicleData?.vin}
      >
        <ProductTable title={t('form:shoppingCart:collAndReturn')}>
          <ProductTable.Section>
            <DescriptionList split>
              <DescriptionList.Group
                termText={t('form:shoppingCart:rentalFees')}
              >
                <DescriptionList.Detail>
                  {formatPrice(vehicleData?.purchasePriceAmount)}
                </DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group
                termText={t('form:shoppingCart:afterPrice')}
              >
                <DescriptionList.Detail> </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>
        </ProductTable>
        <div className="u-pt u-font-size-fs-2">
          {afterPriceTexts?.map((text) => (
            <Paragraph
              key={text}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          ))}
        </div>
      </ShoppingCart>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    storefrontData: getStorefrontData(state),
    formData: getFormData(state),
  }
}

export const ConnectedShoppingCart = connect(mapStateToProps)(
  StorefrontShoppingCart
)
