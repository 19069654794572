import { Analytics } from '../../services/analytics'

const defaultTrackingData = {
  [Analytics.keys.viewChange()]: 'Contact',
}

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  }

  Analytics.trackPage({ ...defaultTrackingData, ...specificTrackingData })
}

export const trackEmail = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'interaction',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.linkInformation(0)]: 'Contact per email',
  }
  Analytics.trackInteraction({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
