import axios from 'axios'

const { REACT_APP_API_GATEWAY } = process.env

export const checkTls = (userMail: string) => {
  return axios
    .post(`${REACT_APP_API_GATEWAY}/tls-check`, {
      email: userMail,
    })
    .then((response) => response.data.supported)
}
