import React, { FunctionComponent } from 'react'
import {
  SectionHeading,
  ContentSection,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

type ExpiredSessionProps = {}

const ExpiredSessionPage: FunctionComponent<ExpiredSessionProps> = () => {
  const { t } = useTranslation()
  return (
    <ContentSection pageWrap>
      <SectionHeading level="3">{t('expiredSession:title')}</SectionHeading>
      <Paragraph>{t('expiredSession:text')}</Paragraph>
    </ContentSection>
  )
}

export { ExpiredSessionPage }
