import * as moment from 'moment'

export const EXPIRY_COUNTDOWN_TIME = 600000

let timeoutTimer

export const expiredSessionLogic = (date, callback) => {
  const timeoutReached = () => {
    if (callback) {
      callback()
    }
  }

  if (timeoutTimer) {
    clearTimeout(timeoutTimer)
  }
  const dateIntoSeconds = moment(date * 1000)
  const nowSeconds = moment()
  // eslint-disable-next-line radix
  const TIMEOUT = parseInt(dateIntoSeconds - EXPIRY_COUNTDOWN_TIME - nowSeconds)
  timeoutTimer = setTimeout(() => timeoutReached(), TIMEOUT)
}
