export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA'

interface SaveFormDataAction {
  type: typeof SAVE_FORM_DATA
  payload: any
}

interface ResetFormDataAction {
  type: typeof RESET_FORM_DATA
}

export type FormActions = SaveFormDataAction | ResetFormDataAction
