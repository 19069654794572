import React from 'react'
import { Checkbox as BronsonCheckbox } from '@vwfs-bronson/bronson-react'
import { getIn } from 'formik'

export function fieldToCheckbox({
  field,
  form,
  input,
  beforeChange,
  beforeBlur,
  ...props
}) {
  const { name } = field
  const { errors, touched } = form

  const fieldError = getIn(errors, name)
  return {
    ...input,
    ...field,
    ...props,
    onChange: (e) => {
      if (beforeChange) {
        beforeChange(e)
      }

      field.onChange(e)
    },
    onBlur: (e) => {
      if (beforeBlur) {
        beforeBlur(e)
      }

      field.onBlur(e)
    },
    value: field.name,
    checked: Array.isArray(field.value) ? !!field.value?.length : !!field.value,
    error: getIn(touched, name) && !!fieldError,
  }
}

export function CheckboxField({ children, ...props }) {
  const { label } = props
  return (
    <BronsonCheckbox {...fieldToCheckbox(props)}>
      {label}
      {children}
    </BronsonCheckbox>
  )
}
