import React from 'react'
import MaskedInput from 'react-text-mask'

export function InputDate({ ...props }) {
  const createDateMask = () => {
    return [
      /[\d]/,
      /[\d]/,
      '.',
      /[\d]/,
      /[\d]/,
      '.',
      /[\d]/,
      /[\d]/,
      /[\d]/,
      /[\d]/,
    ]
  }

  const { error, ...otherProps } = props

  return (
    <MaskedInput
      {...otherProps}
      className={`c-input__input ${error ? 'is-error' : ''}`}
      guide={false}
      mask={createDateMask}
      placeholder="TT.MM.JJJJ"
    />
  )
}
