import React from 'react'
import {
  Card,
  Fieldset,
  Paragraph,
  ShowMore,
  ErrorMessage as BronsonErrorMessage,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, FastField } from 'formik'
import { CheckboxField } from '../../../../../components/CheckboxField'

const PrivacyComponent = (props) => {
  const { t } = useTranslation()
  const { tlsValid, dataProtectionUrl } = props
  return (
    <Card className="u-text-left">
      <Fieldset.Row>
        <h4 className="u-h4">{t('form:dataProtection:dsi:title1')}</h4>
        <>
          {t('form:dataProtection:dsi:explanationTextFirst')}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={dataProtectionUrl()}
          >
            {t('form:dataProtection:dsi:documentName')}
          </a>
          {t('form:dataProtection:dsi:explanationTextLast')}
        </>
      </Fieldset.Row>

      <Fieldset.Row>
        <FastField
          name="allowAllAds"
          label={t('form:dataProtection:dse:checkboxCheckAll:explanationText')}
          component={CheckboxField}
        />
      </Fieldset.Row>
      <Fieldset.Row>
        <b>{t('form:dataProtection:dse:subtitle1')}</b>
        <Paragraph>{t('form:dataProtection:dse:text1')}</Paragraph>
      </Fieldset.Row>
      <Fieldset.Row>
        <FastField
          name="allowPhoneAds"
          label={t('form:dataProtection:dse:checkboxElectronical:consentPhone')}
          component={CheckboxField}
        />
      </Fieldset.Row>
      <Fieldset.Row>
        <FastField
          name="allowMailAds"
          label={t('form:dataProtection:dse:checkboxElectronical:consentEMail')}
          component={CheckboxField}
        />
      </Fieldset.Row>
      <Fieldset.Row>
        <ShowMore
          labelCollapse={t('form:dataProtection:dse:lessDetailsText')}
          labelExpand={t('form:dataProtection:dse:moreDetailsText')}
        >
          <Paragraph>
            {t('form:dataProtection:dse:electronicDetails.text1')}
          </Paragraph>
          <Paragraph className="u-font-size-fs-2">
            {t('form:dataProtection:dse:electronicDetails.text2')}
          </Paragraph>
          <Paragraph className="u-font-size-fs-2">
            {t('form:dataProtection:dse:electronicDetails.text3')}
          </Paragraph>
        </ShowMore>
      </Fieldset.Row>

      <Fieldset.Row>
        <b>{t('form:dataProtection:dse:subtitle2')}</b>
      </Fieldset.Row>
      <Fieldset.Row>
        <FastField
          name="allowPersonalizedMarketing"
          label={t('form:dataProtection:dse:personalMarketingCheckbox')}
          component={CheckboxField}
        />
      </Fieldset.Row>
      <Fieldset.Row>
        <ShowMore
          labelCollapse={t('form:dataProtection:dse:lessDetailsText')}
          labelExpand={t('form:dataProtection:dse:moreDetailsText')}
        >
          <Paragraph>
            {t('form:dataProtection:dse:personalMarketingDetails.text1')}
          </Paragraph>
          <Paragraph className="u-font-size-fs-2">
            {t('form:dataProtection:dse:personalMarketingDetails.text2')}
          </Paragraph>
        </ShowMore>
      </Fieldset.Row>
      {!tlsValid && (
        <>
          <Fieldset.Row>
            <Paragraph>{t('form:dataProtection:dse:text2')}</Paragraph>

            <Paragraph>
              <b>{t('form:dataProtection:dse:subtitle3')}</b>
            </Paragraph>

            <Paragraph>{t('form:dataProtection:dse:text3')}</Paragraph>
          </Fieldset.Row>

          <Fieldset.Row>
            <FastField
              name="allowMailCommunication"
              label={t(
                'form:dataProtection:dse:allowMailCommunicationCheckbox'
              )}
              component={CheckboxField}
            />
            <ErrorMessage
              name="allowMailCommunication"
              component={BronsonErrorMessage}
            />
          </Fieldset.Row>
        </>
      )}
    </Card>
  )
}

export default PrivacyComponent
