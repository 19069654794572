import { Analytics } from '../../services/analytics'

export const pageName = 'home'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = ({ firstOpen }) => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  }
  const onFirstOpenData = {
    [Analytics.keys.eventType(1)]: 'Product journey',
    [Analytics.keys.eventAction(1)]: 'Start',
    [Analytics.keys.eventType(2)]: 'Product configuration',
    [Analytics.keys.eventAction(2)]: 'Completed',
    [Analytics.keys.eventType(3)]: 'Product price',
    [Analytics.keys.eventAction(3)]: 'Calculated',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
    ...(firstOpen && onFirstOpenData),
  })
}

export const trackDirty = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'Form',
    [Analytics.keys.eventAction(0)]: 'Start typing',
    [Analytics.keys.linkInformation(0)]:
      'Personal data (personal data): Start typing',
  }
  Analytics.trackInteraction({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
