import axios from 'axios'
import { SessionDataHandling } from './SessionDataHandling'

const { REACT_APP_API_GATEWAY } = process.env

const withoutAuthorizationPaths = ['/validate-tan', '/send-tan']

const getCommonHeadersForBftServices = () => {
  return {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  }
}

export const installCommonHeadersForBffServicesInterceptor = () => {
  axios.interceptors.request.use((config) => {
    const needsAuthHeader =
      config.url?.indexOf(REACT_APP_API_GATEWAY!) === 0 &&
      !withoutAuthorizationPaths.find((path) => config.url?.includes(path))

    if (!needsAuthHeader) {
      return config
    }

    const sessionData = SessionDataHandling.getSessionDataFromStorage()

    return {
      ...config,
      headers: {
        ...config.headers,
        common: {
          ...config.headers.common,
          ...getCommonHeadersForBftServices(),
          Authorization: sessionData.token || '',
        },
      },
    }
  })
}
