import { Modal, Paragraph, Spinner } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  confirmationRefuse,
  getConfirmationData,
} from '../../../services/api/confirmation'
import * as actions from '../../../services/redux/features/storefront.redux'
import { store } from '../../../services/redux'
import { history } from '../../../services/routing'
import routes from '../../../routes'
import { isCorrectEntryState } from '../transactions.utils'

export type TransactionsRefusalPageProps = {
  storefrontData: any
  match: any
}

const TransactionsRefusalPage: FunctionComponent<
  TransactionsRefusalPageProps
> = (props) => {
  const { storefrontData, match } = props
  const { t } = useTranslation()
  const [isLoading, setIsloading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const getData = async () => {
    setIsReady(false)
    try {
      const data = await getConfirmationData(match?.params?.id)
      if (!isCorrectEntryState(data)) {
        return history.push(routes.transactionsError)
      }
      store.dispatch(actions.storeId(match?.params?.id))
      store.dispatch(actions.initial(data))
    } catch {
      history.push(routes.errorPage)
    } finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOriginSystem = () => storefrontData?.salesChannel?.originSystem

  const onConfirm = async () => {
    setIsloading(true)
    try {
      await confirmationRefuse(match?.params?.id, match?.params?.acceptanceId)
      history.push(routes.transactionsCanceled)
    } catch {
      history.push(routes.errorPage)
    } finally {
      setIsloading(false)
    }
  }

  return (
    <>
      {(isLoading || !isReady) && <Spinner center fullPage />}
      {isReady && (
        <Modal
          center
          title={t(`transactions:${getOriginSystem()}:refusal:title`, {
            defaultValue: t(`transactions:default:refusal:title`),
          })}
          shown={true}
          hideCloseButton
          buttonConfirmText={t(
            `transactions:${getOriginSystem()}:refusal:confirmButton`,
            {
              defaultValue: t(`transactions:default:refusal:confirmButton`),
            }
          )}
          onConfirm={onConfirm}
        >
          <Paragraph className="u-pt u-pb">
            {t(`transactions:${getOriginSystem()}:refusal:subtitle`, {
              defaultValue: t(`transactions:default:refusal:subtitle`),
            })}
          </Paragraph>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(TransactionsRefusalPage)
