import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'

export type TransactionsErrorPageProps = {}

const TransactionsErrorPage: FunctionComponent<
  TransactionsErrorPageProps
> = () => {
  const { t } = useTranslation()

  return (
    <ContentSection pageWrap>
      <SectionHeading level="2">{t('transactions:error:title')}</SectionHeading>
      <Paragraph testId="errorText">{t('transactions:error:text')}</Paragraph>
    </ContentSection>
  )
}

const mapStateToProps = (state) => {}

export default connect(mapStateToProps, {})(TransactionsErrorPage)
