export const SELECT_CHOOSE_VALUE = {
  label: 'Bitte wählen...',
  value: 'NONE',
}

export const MIN_YEARS_OF_RESIDENCE = 3
export const MIN_MONTHS_OF_EMPLOYMENT = 6
const envs = ['.dev.', '.int.', '.cons.', 'localhost']
export const isDev = () => {
  if (new RegExp(envs.join('|')).test(window.location.host)) {
    return true
  }
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true
  }
  return false
}
