import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { getBrandName } from './config'
import { DEFAULT_DATALAYER } from './datalayer'
import { setDataModels } from './services/redux/features/data-models.redux'
import { Analytics } from './services/analytics'
import { history, qs } from './services/routing'
import { brandNames } from './resources/index'
import Routes from './components/Routes'
import * as baseModels from './resources/base/models'
import routes from './routes'
import { setBreakPoint } from './services/analytics/functions'
import './app.css'

const Loading = () => (
  <div className="u-text-center">
    <br />
    <br />
    <Spinner center />
    <br />
    <br />
  </div>
)

type AppStateProps = {
  setDataModels: any
  storefrontData: any
  token: string
}

export const App = (props: AppStateProps) => {
  const { t } = useTranslation()

  const { setDataModels: setModels, storefrontData } = props

  const [isReady, setReady] = React.useState(false)
  const [entryUrl, setEntryUrl] = React.useState('')

  const [identificationId] = React.useState(() => {
    return qs(document.location.search, 'transaction-number')
  })
  const pagesWithoutStorefrontId = [routes.dataProtectionInfo]

  const [storefrontId] = React.useState(() => {
    setEntryUrl(window.location.href)
    const id = qs(document.location.search, 'id')

    const splittedPathName = `/${window.location.pathname?.split('/')?.[1]}`

    if (pagesWithoutStorefrontId.includes(window.location.pathname)) {
      return
    }
    if (
      id === '' &&
      identificationId === '' &&
      window.location.pathname !== '/error' &&
      splittedPathName !== '/transactions'
    ) {
      history.push(routes.errorPage)
    }
    // eslint-disable-next-line consistent-return
    return id
  })

  React.useEffect(() => {
    setModels({ ...baseModels })
    setReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModels])

  React.useEffect(() => {
    setBreakPoint()
  }, [storefrontData])

  React.useEffect(() => {
    Analytics.setDefaults(DEFAULT_DATALAYER)
  }, [])

  React.useEffect(() => {
    document.title = `${t('app:pageTitle')}`
    const description: HTMLMetaElement | undefined = Array.from(
      document.getElementsByTagName('meta')
    ).find((meta) => {
      return meta.name.toLocaleLowerCase() === 'description'
    })
    if (description) {
      description.content = `${brandNames[getBrandName()]} ${t(
        'app:pageTitle'
      )}`
    }
  }, [t])

  return (
    <>
      <div className="o-root">
        {isReady ? (
          <Routes
            storefrontId={storefrontId}
            identificationId={identificationId}
            entryUrl={entryUrl}
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {
  setDataModels,
})(App)
