import React, { FunctionComponent, PropsWithChildren } from 'react'
import { history } from '../../services/routing'

export const HtmlContent: FunctionComponent<PropsWithChildren> = ({
  children,
  ...props
}) => {
  const handleClick = (e) => {
    const href = e.target.getAttribute('href')
    if (href && href.startsWith('/')) {
      e.preventDefault()
      history.push(href)
    }
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      {...props}
      onClick={handleClick}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: children as any }}
    />
  )
}
