/* eslint-disable  */
import get from 'lodash/get'
import { Analytics } from './index'
import { convertDateString, date } from '../common/format'
import { isBusiness, isVaFa, isVtiGiS } from '../../services/common/utils'

const pushSpecificDataToDatalayer = (storefrontData) => {
  return Analytics.addToDatalayer({
    [Analytics.keys.context()]: isVtiGiS(storefrontData) ? 'TopCars' : 'E-Mail',
    [Analytics.keys.loggedInUserGroup()]:
      isVaFa(storefrontData) && isBusiness(storefrontData)
        ? 'Business'
        : 'Private',
  })
}

export function pushStorefrontDataToDatalayer(storefrontData) {
  const monthlyTotalRateAmount = get(
    storefrontData,
    'financialProduct.calculation.monthlyTotalRateAmount'
  )
  const duration = get(storefrontData, 'financialProduct.calculation.duration')
  const durationUnit = get(
    storefrontData,
    'financialProduct.calculation.durationUnit'
  )
  const mileageUnit = get(
    storefrontData,
    'financialProduct.calculation.mileageUnit'
  )
  const monthlyMileage = get(
    storefrontData,
    'financialProduct.calculation.monthlyMileage'
  )
  const pickupLocation = get(storefrontData, 'vehicleData.pickupLocation')
  const subscriptionVehicleCategory = get(
    storefrontData,
    'financialProduct.subscriptionVehicleCategory'
  )
  const inceptionDate = get(storefrontData, 'custom.contract.inceptionDate')
  const dateOfBirth = get(storefrontData, 'customerData.dateOfBirth')
  const additionalServicesDescriptions = get(
    storefrontData,
    'financialProduct.additionalServicesDescriptions'
  )
  const dealerData = get(storefrontData, 'dealerData')
  const dealerDataAddress = get(dealerData, 'additionalDealers[0].addresses[0]')
  const dealerDataName = get(dealerData, 'additionalDealers[0].companyName')
  const dealerDataCompanyId = get(dealerData, 'companyId')
  const vehicleDataModel = get(storefrontData, 'vehicleData.model')
  const vehicleModelEngine = get(vehicleDataModel, 'engine')
  const vehicleModelManufacturer = get(vehicleDataModel, 'manufacturer')
  const vehicleModelName = get(vehicleDataModel, 'name')
  const vehicleModelDescriptionLong = get(vehicleDataModel, 'descriptionLong')
  const vehicleModelTypeCode = get(vehicleDataModel, 'typeCode')
  const vehicleModelColorExterior = get(vehicleDataModel, 'colorExterior')
  const vehicleModelEngineEnginePower = get(vehicleModelEngine, 'power')
  const vehicleModelEngineTransmission = get(
    vehicleDataModel,
    'transmissionDescription'
  )
  const contractAmount = get(storefrontData, 'vehicleData.purchasePriceAmount')
  const transactionId = get(storefrontData, 'transaction.id')

  // Add to datalayer specific data (based on context. F.e: originSystem)
  pushSpecificDataToDatalayer(storefrontData)
  return Analytics.addToDatalayer({
    ...(transactionId
      ? { 'core.attributes.transactionId': transactionId }
      : {}),
    ...(process.env.REACT_APP_WEBSITE_BRAND && {
      'core.attributes.brand': process.env.REACT_APP_WEBSITE_BRAND,
    }),
    ...(monthlyTotalRateAmount || monthlyTotalRateAmount === 0
      ? { 'product[0].attributes.recurringPayment': monthlyTotalRateAmount }
      : {}),
    ...(duration || duration === 0
      ? { 'product[0].attributes.duration': duration }
      : {}),
    ...(durationUnit
      ? { 'product[0].attributes.durationUnit': durationUnit }
      : {}),
    ...(contractAmount || contractAmount === 0
      ? {
          'product[0].attributes.contractAmount': contractAmount,
        }
      : {}),
    ...(mileageUnit
      ? { 'product[0].attributes.mileageUnit': mileageUnit }
      : {}),
    ...(monthlyMileage
      ? { 'product[0].attributes.yearlyMileage': monthlyMileage * 12 }
      : {}),
    ...(pickupLocation
      ? {
          'product[0].attributes.addOns[0]':
            pickupLocation === 'CUSTOMERADDRESS'
              ? { name: 'home delivery', additionalInformation: null }
              : { name: null, additionalInformation: null },
        }
      : {}),
    ...(subscriptionVehicleCategory
      ? { 'product[0].vehicleModel[0].category': subscriptionVehicleCategory }
      : {}),
    ...(inceptionDate
      ? {
          'product[0].attributes.startDateOfContract': convertDate(
            inceptionDate,
            { from: 'DD.MM.YYYY', to: 'YYYY-MM-DD' }
          ),
        }
      : {}),
    ...(dateOfBirth
      ? {
          'customerData.yearOfBirth': convertDateString(
            date(dateOfBirth).getFullYear()
          ),
        }
      : {}),
    ...(additionalServicesDescriptions?.length
      ? {
          'product[0].attributes.addOns[1].name': additionalServicesDescriptions
            .map((service) => service.servicePackageLabel)
            .join(', '),
        }
      : {}),

    ...(dealerDataCompanyId
      ? { 'dealerData.companyId': dealerDataCompanyId }
      : {}),
    ...(dealerDataName ? { 'dealerData.companyName': dealerDataName } : {}),
    ...(dealerDataAddress
      ? { 'dealerData.address.street': dealerDataAddress?.street }
      : {}),
    ...(dealerDataAddress
      ? { 'dealerData.address.city': dealerDataAddress?.city }
      : {}),
    ...(dealerDataAddress
      ? { 'dealerData.address.countryCode': dealerDataAddress?.countryCode }
      : {}),
    ...(dealerDataAddress
      ? { 'dealerData.address.zipCode': dealerDataAddress?.zipCode }
      : {}),

    ...(vehicleModelManufacturer
      ? { 'product[0].vehicleModel[0].manufacturer': vehicleModelManufacturer }
      : {}),

    ...(vehicleModelName
      ? { 'product[0].vehicleModel[0].name': vehicleModelName }
      : {}),

    ...(vehicleModelDescriptionLong
      ? {
          'product[0].vehicleModel[0].descriptionLong':
            vehicleModelDescriptionLong,
        }
      : {}),

    ...(vehicleModelTypeCode
      ? { 'product[0].vehicleModel[0].typeCode': vehicleModelTypeCode }
      : {}),

    ...(vehicleModelColorExterior
      ? {
          'product[0].vehicleModel[0].colorExterior': vehicleModelColorExterior,
        }
      : {}),
    ...(vehicleModelEngineTransmission
      ? {
          'product[0].vehicleModel[0].engine.transmission':
            vehicleModelEngineTransmission,
        }
      : {}),
    ...(vehicleModelEngineEnginePower
      ? {
          'product[0].vehicleModel[0].engine.enginePower': `${vehicleModelEngineEnginePower} PS (${
            vehicleModelEngineEnginePower * 0.746
          } kW)`,
        }
      : {}),
  })
}
