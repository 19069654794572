export const FETCH_TRANSACTION_PENDING =
  'transaction//FETCH_TRANSACTION_PENDING'
export const FETCH_TRANSACTION_DONE = 'transaction//FETCH_TRANSACTION_DONE'
export const FETCH_TRANSACTION_ERROR = 'transaction//FETCH_TRANSACTION_ERROR'

interface PendingAction {
  type: typeof FETCH_TRANSACTION_PENDING
  payload: {}
}

interface DoneAction {
  type: typeof FETCH_TRANSACTION_PENDING
  payload: any
}

interface ErrorAction {
  type: typeof FETCH_TRANSACTION_ERROR
  payload: any
}

export type TransactionActions = PendingAction | DoneAction | ErrorAction
