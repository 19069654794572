import { Analytics } from '../../services/analytics'

export const pageName = 'Confirmation'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'ProductInformation:Contact Information',
    [Analytics.keys.eventAction(0)]: 'Provided',
    [Analytics.keys.eventType(1)]: 'ProductInformation:Personal Information',
    [Analytics.keys.eventAction(1)]: 'Provided',
    [Analytics.keys.eventType(2)]: 'Sale',
    [Analytics.keys.eventAction(2)]: 'Success',
    [Analytics.keys.eventType(3)]: 'Product journey',
    [Analytics.keys.eventAction(3)]: 'End',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
