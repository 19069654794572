import React from 'react'
import { ButtonContainer, Button, Modal } from '@vwfs-bronson/bronson-react'
import * as H from 'history'
import { useTranslation } from 'react-i18next'

import { Analytics } from '../../services/analytics'

import routes from '../../routes'

export const NavigationBlockedModal = (props) => {
  const [visible, setVisible] = React.useState(false)
  const pageName = 'Browser navigation unavailable'
  const { t } = useTranslation()
  const show = () => {
    setVisible(true)
    trackPage()
  }

  const hide = () => {
    setVisible(false)
  }
  const defaultTrackingData = {
    [Analytics.keys.pageName()]: pageName,
    [Analytics.keys.viewChange()]: pageName,
  }
  const trackPage = () => {
    setTimeout(() => {
      const pageData = {
        [Analytics.keys.eventType(0)]: 'pageView',
        [Analytics.keys.eventAction(0)]: 'Success',
      }
      Analytics.trackPage({ ...defaultTrackingData, ...pageData })
    }, 1000)
  }
  const { history } = props
  // eslint-disable-next-line no-shadow
  const registerBlockingNavigation = (history: H.History) => {
    let currentPathname = ''
    let currentSearch = ''

    history.listen((newLocation, action) => {
      if (action === 'PUSH') {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          // GFTDE-900 workaround: Clear the viewChange variable on navigate
          //          Analytics.clearViewChange();

          // Save new location
          currentPathname = newLocation.pathname
          currentSearch = newLocation.search

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
            state: newLocation.state,
          })
        }
      } else {
        if (history.location.pathname === routes.errorPage) {
          // Skip blocking on error page
          return
        }

        // Send user back if they try to navigate back
        history.go(1)
        show()
      }
    })
  }

  React.useEffect(() => {
    registerBlockingNavigation(history)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      title={t('navigationBlockedModal:title')}
      onClose={() => {
        hide()
      }}
      shown={visible && history.location.pathname !== routes.errorPage}
    >
      {t('navigationBlockedModal:text')}

      <ButtonContainer center className="u-mt">
        <Button onClick={() => hide()}>
          {t('navigationBlockedModal:close')}
        </Button>
      </ButtonContainer>
    </Modal>
  )
}
