import { Analytics } from '../../services/analytics'

export const pageName = 'Vehicle unavailable'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'Product journey',
    [Analytics.keys.eventAction(0)]: 'End',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
