import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import get from 'lodash/get'
import { history } from '../../services/routing'

interface FormSectionGroupProps {
  children: any
  validSections: any
  alwaysOpen: any
}

export const FormSectionGroup: FunctionComponent<FormSectionGroupProps> = ({
  children,
  validSections,
  alwaysOpen,
}) => {
  const [visibleIndex, setVisibleIndex] = React.useState(0)
  const [validStates, setValidStates] = React.useState(validSections)

  useEffect(() => {
    setValidStates(validSections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSections])

  useEffect(() => {
    setVisibleIndex(validStates.indexOf(false))
  }, [validStates])

  const isHidden = (child, index) => {
    return alwaysOpen.indexOf(child.props.id) > -1
      ? false
      : visibleIndex !== index
  }

  React.Children.forEach(children, (child, index) => {
    if (child.props.open) {
      if (visibleIndex !== index) {
        setVisibleIndex(index)
      }
    }
  })

  const items = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      onHeaderClick: () => {
        return validStates[index] && setVisibleIndex(index)
      },
      hidden: isHidden(child, index),
      onValid: (valid) => {},
      success: validStates[index],
      omitActive: alwaysOpen.indexOf(child.props.id) !== -1,
    })
  })
  return <div className="c-form-section-group">{items}</div>
}

interface FormSectionProps {
  success?: any
  onHeaderClick?: Function
  hidden?: boolean
  onValid?: Function
  children?: any
  omitActive?: any
  open?: any
  id?: string
}

export const FormSectionSection: FunctionComponent<FormSectionProps> = ({
  success,
  onHeaderClick,
  hidden,
  onValid,
  children,
  omitActive,
}) => {
  const isActive = (isHidden, isOmitActive) => {
    return isOmitActive ? !success : !isHidden
  }

  const header = React.Children.toArray(children)
    .filter((child: any) => child?.type === FormSectionHeader)
    .map((child: any) => {
      return React.cloneElement(child, {
        onHeaderClick,
        active: isActive(hidden, omitActive),
      })
    })
  const content = React.Children.toArray(children)
    .filter((child: any) => child.type === FormSectionContent)
    .map((child: any) =>
      React.cloneElement(child, {
        hidden,
        onValid,
      })
    )
  return (
    <section
      className={
        // eslint-disable-next-line prefer-template
        'c-form-section  js-form-section' +
        (success ? ' c-form-section--success' : '') +
        (isActive(hidden, omitActive) ? ' is-open' : '')
      }
    >
      {header}
      {content}
    </section>
  )
}

interface FormSectionHeaderProps {
  active?: boolean
  onHeaderClick?: any
  children?: any
}
export const FormSectionHeader: FunctionComponent<FormSectionHeaderProps> = ({
  active,
  onHeaderClick,
  children,
}) => {
  return (
    <button
      className={
        // eslint-disable-next-line prefer-template
        'c-form-section__header  js-form-section__header' +
        (active ? ' is-active' : '')
      }
      type="button"
      tabIndex={0}
      onClick={onHeaderClick}
      style={{ outline: 'none' }}
      aria-expanded={active}
    >
      <span className="c-form-section__header-content">{children}</span>
      <span className="c-form-section__header-icon" />
    </button>
  )
}

export const FormSectionSubtitle: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return <span className="c-form-section__subtitle">{children}</span>
}

export const FormSectionTitle: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <span
        className="c-form-section__title  js-form-section__trigger"
        aria-expanded="false"
      >
        <span className="c-form-section__title-text">{children}</span>
      </span>
    </>
  )
}

interface FormSectionContentProps {
  onValid?: Function
  hidden?: boolean
  children?: any
}
export const FormSectionContent: FunctionComponent<FormSectionContentProps> = ({
  onValid,
  hidden,
  children,
}) => {
  const hiddenStyle = {}
  const visibleStyle = {
    overflow: 'visible' as 'visible',
  }
  const items = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onValid,
      hidden,
    })
  )
  const opts = {}
  if (hidden) {
    opts['inert'] = 'true'
  }
  return (
    <>
      {!hidden && (
        <div
          className="c-form-section__panel  js-form-section__panel"
          aria-hidden={hidden}
          {...opts}
        >
          <div
            className="c-form-section__content"
            style={hidden ? hiddenStyle : visibleStyle}
          >
            {items}
          </div>
        </div>
      )}
    </>
  )
}

export const resetOpenSectionState = () => {
  if (
    get(history, 'location.pathname') &&
    get(history, 'location.state.sectionId')
  ) {
    history.replace(history.location.pathname, {
      storefrontId: get(history, 'location.state.storefrontId'),
    })
  }
}
