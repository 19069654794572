/* eslint-disable no-unused-vars */
export enum AddressType {
  PRIMARY = 0,
  SECONDARY = 1,
}
export enum LegalFormType {
  SOLE_TRADER = 'SOLE_TRADER',
  REGISTERED_SOLE_TRADER = 'REGISTERED_SOLE_TRADER',
  FREELANCER = 'FREELANCER',
}
export enum VehicleDefects {
  DEFECTS = 'DEFECTS',
  NO_DEFECTS = 'NO_DEFECTS',
}
export enum LicenseClassType {
  B = 'B',
  TWO = '2',
  THREE = '3',
  OTHER = 'Andere',
}

export enum TruthType {
  TRUE = 'true',
  FALSE = 'false',
}

export enum PickupLocationType {
  CUSTOMERADDRESS = 'CUSTOMERADDRESS',
  OTHERADDRESS = 'OTHERADDRESS',
}

export enum OauthProviders {
  VERIMI = 'Verimi',
  VW = 'Volkswagen ID',
}

// Enum key is <FrontendBrand_AuthorizationProvider>.
// Enum value is defined by Id Broker
export enum OauthProvidersHint {
  VWFS_VERIMI = 'verimivwfs',
  VWFS_VW = 'identitykitaabovwfs',
  VW_VW = 'identitykitaabovw',
}

export enum DealerRole {
  PICKUP = 'PICKUP',
}

export enum TestProcedureType {
  WLTP = 'WLTP',
  NEDC = 'NEDC',
}

export enum EmissionsType {
  CO2 = 'CO2',
  NOX = 'NOX',
}

export enum TransactionStatus {
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
}

export enum EngineType {
  INTERNAL_COMBUSTION_ENGINE = 'INTERNAL_COMBUSTION_ENGINE',
  EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE = 'EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE',
  NOT_EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE = 'NOT_EXTERNALLY_CHARGEABLE_HYBRID_ELECTRIC_VEHICLE',
  PURE_ELECTRIC_VEHICLE = 'PURE_ELECTRIC_VEHICLE',
}

export enum EnergyManagementType {
  UNESPECIFIED = 'UNESPECIFIED',
  PURE = 'PURE',
  SUSTAINING = 'SUSTAINING',
}

export enum MimeType {
  SVG = 'image/svg+xml',
  PDF = 'application/pdf',
}

export enum BasicPollingStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum manufacturers {
  VW = 'Volkswagen',
  VWCV = 'Volkswagen Nutzfahrzeuge',
  SEAT = 'SEAT',
  SKODA = 'Skoda',
  AUDI = 'Audi',
  CUPRA = 'CUPRA',
}
