import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
  Button,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'
import { history } from '../../services/routing'
import routes from '../../routes'
import { trackPage } from './tracking'

export type ErrorPageProps = {
  storefrontId: string
  entryUrl: string
  errorCausingURL: string
}

const ErrorPage: FunctionComponent<ErrorPageProps> = (props) => {
  const { t } = useTranslation()
  const { storefrontId, errorCausingURL } = props
  const landingPageRoute = storefrontId
    ? `${routes.landingPage}?id=${storefrontId}`
    : routes.errorPage

  React.useEffect(() => {
    document.title = `${t('error:tabTitle')}`
    trackPage(errorCausingURL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return (
    <ContentSection pageWrap>
      <SectionHeading level="2" center testId="errorHeading">
        {t('error:heading')}
      </SectionHeading>
      <Paragraph testId="errorText" className="u-text-center">
        {t('error:text1')}
      </Paragraph>
      <Paragraph testId="linkErrorText" className="u-text-center">
        <Button
          id="tryAgainButton"
          testId="tryAgainButton"
          type="submit"
          onClick={() => {
            history.push(landingPageRoute)
          }}
        >
          {t('error:button:text')}
        </Button>
      </Paragraph>
    </ContentSection>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontId: state.storefront.id,
    errorCausingURL: state.custom.data.errorData.url,
  }
}

export default connect(mapStateToProps, {})(ErrorPage)
