/* eslint-disable no-nested-ternary */
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import {
  Button,
  ContentSection,
  Modal,
  Spinner,
} from '@vwfs-bronson/bronson-react'
import {
  done,
  getStoreFrontId,
  getStorefrontData,
  setDealerPaymentData,
} from '../../services/redux/features/storefront.redux'
import { StorefrontShoppingCart } from '../../components/ShoppingCart'
import {
  FormSectionGroup as Group,
  FormSectionHeader as Header,
  FormSectionContent as Content,
  FormSectionSection as Section,
  FormSectionTitle as Title,
  FormSectionSubtitle as Subtitle,
} from '../../components/FormSection'
import { HtmlContent } from '../../components/HtmlContent'
import { history, qs } from '../../services/routing'
import {
  saveFormData as saveFormDataAction,
  getFormData,
} from '../../services/redux/features/form.redux'

import DataProtection from './DataProtection'
import { scrollToElement } from '../../services/common/form'
import PersonalDetails from './PersonalDetails'
import {
  isPrivate,
  isBusiness,
  isVtiGiS,
  saveSesionData,
  isDealershop,
  isVaFa,
} from '../../services/common/utils'
import { completeJourney } from '../../services/api/submitData/complete-journey'
import routes from '../../routes'
import { VehicleDefects } from '../../models/enums'
import { trackDirty } from './tracking'
import './appPage.css'
import EmailValidationModal from '../../components/EmailValidationModal/EmailValidationModal'
import AdditionalProducts from './AdditionalProducts'
import { setTokenExpiresAt as setTokenExpiresAtAction } from '../../services/redux/features/session.redux'
import InstructionComponent from './InstructionStepper'
import VehicleconditionVafa from './VehicleCondition/VehicleconditionVafa'
import VehicleConditionDealershop from './VehicleCondition/VehicleConditionDealershop'
import { getServiceAndInspection } from '../../services/redux/features/custom.redux'

const AppForm = (props) => {
  const {
    editPage,
    location,
    storefrontData,
    saveFormData,
    done: saveStorefrontData,
    storefrontId,
    serviceAndInspection,
    setTokenExpiresAtAction: setTokenExpiresAt,
  } = props
  const { t } = useTranslation()
  const personalDetailsRef = React.useRef(undefined as any)
  const vehicleConditionRef = React.useRef(undefined as any)
  const [valuesPersonalForm, setValuesPersonalForm] = React.useState(
    undefined as any
  )
  const [hasDefectsSelected, setHasDefectsSelected] = React.useState(
    undefined as any
  )
  const [vehicleConditionValues, setVehicleConditionValues] = React.useState(
    {} as any
  )
  const [openFormSection, setOpenFormSection] = React.useState(undefined as any)
  const [showErrorOnConnect, setshowErrorOnConnect] = React.useState(false)
  const [hasSubmitedDataProtection, setHasSubmitedDataProtection] =
    React.useState(false)
  const [isloading, setisloading] = React.useState(false)
  const [isDirty, setIsDirty] = React.useState(false)
  const isBusinessPath = () => isBusiness(storefrontData)
  const showVehicleCondition =
    (isPrivate(storefrontData) && isVaFa(storefrontData)) ||
    isDealershop(storefrontData)
  const [showEmailValidationModal, setShowEmailValidationModal] =
    React.useState(false)

  React.useEffect(() => {
    if (editPage === true) {
      const ids = ['personalDetails', 'contactDetails']
      const sectionNumber = Number(qs(location.search, 'section'))
      setOpenFormSection(ids[sectionNumber])
    }

    if (
      props.storefrontId ||
      get(props, 'history.location.state.storefrontId')
    ) {
      document.title = t('form:tabTitle')
    } else {
      history.pushPreservePath(routes.errorPage)
    }
    // we don't need props and t as dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (openFormSection) {
      validateAllForms()
      if (!document.querySelector('.is-error')) {
        scrollToElement('.c-form-section__header.is-active')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    saveFormData(vehicleConditionValues)
    setHasDefectsSelected(
      vehicleConditionValues.condition === VehicleDefects.DEFECTS
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleConditionValues])

  React.useEffect(() => {
    if (isDirty) {
      trackDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])
  const finishJourney = async (consentData) => {
    const formCustomerData = personalDetailsRef.current?.getCustomerData()
    const formCompanyData = personalDetailsRef.current?.getCompanyData()
    setisloading(true)
    const data = {
      customerData: formCustomerData,
      ...(isBusinessPath() && {
        companyData: formCompanyData,
      }),
      consentData,
      ...(showVehicleCondition &&
        !isDealershop(storefrontData) && {
          vehicleData: {
            condition: vehicleConditionValues.condition,
            defectsDescription: vehicleConditionValues.defectsDescription,
            odometer: Number(vehicleConditionValues.odometer),
          },
          ...(consentData && {
            consentData: {
              ...consentData,
              acceptVehicleDefects: vehicleConditionValues.acceptVehicleDefects,
              acceptLimitationPeriodVafa:
                vehicleConditionValues.acceptLimitationPeriodVafa,
            },
          }),
        }),
      ...(showVehicleCondition &&
        isDealershop(storefrontData) && {
          consentData: {
            ...consentData,
            acceptVehicleDefects: vehicleConditionValues.acceptVehicleDefects,
            acceptUpdateObligation:
              vehicleConditionValues.acceptUpdateObligation,
          },
        }),
      ...(isVtiGiS(storefrontData) && {
        additionalProducts: storefrontData?.financialProduct?.additionalProducts
          ?.filter((p: any) => p.isSelected)
          ?.map((p: any) => p.code),
      }),
      ...((isVtiGiS(storefrontData) || isDealershop(storefrontData)) && {
        serviceAndInspection,
      }),
    }

    try {
      const response = await completeJourney(data)
      setisloading(false)
      const hasError = checkResult(response)
      if (!hasError) {
        saveSesionData({
          transactionId: storefrontId,
          token: response?.data?.token,
        })
        setTokenExpiresAt(response?.data?.tokenExpiresAt)
        setShowEmailValidationModal(true)
      }
    } catch (error) {
      history.push(routes.errorPage)
    }
  }

  const checkResult = (response) => {
    const status = response?.status?.toString()
    let target
    if (status !== '200') {
      if (status === '409') {
        target = routes.vehicleNotAvailablePage
      } else {
        target = routes.errorPage
      }
      history.push(target)
      return true
    }
    return false
  }

  const validateAllForms = async () => {
    await personalDetailsRef.current?.submit()
    const personalDataValidation = personalDetailsRef?.current?.validate()
    const vehicleConditionValidation = vehicleConditionRef?.current?.validate()

    return showVehicleCondition
      ? personalDataValidation && vehicleConditionValidation
      : personalDataValidation
  }

  const getFormSectionsValidSections = () => {
    return [false]
  }

  const onErrorFetch = () => {
    setshowErrorOnConnect(true)
  }
  const validProps = {
    finishJourney,
    onErrorFetch,
  }
  const getFormValuesDynamically = (values) => {
    setValuesPersonalForm(values)
  }
  const sections = [
    {
      id: 'personalDetails',
      alwaysOpen: false,
      trackingName: 'form:personalDetails:analyticsName',
      title: 'personal-details:title',
      subtitle: `personal-details:subtitle${
        // eslint-disable-next-line
        isVtiGiS(storefrontData)
          ? 'VtiGis'
          : isBusiness(storefrontData)
          ? 'VafaBusiness'
          : ''
      }`,
      child: (
        <PersonalDetails
          isBusiness={isBusinessPath()}
          isVtiGis={isVtiGiS(storefrontData)}
          {...validProps}
          ref={personalDetailsRef}
          editPage={editPage}
          setIsDirty={setIsDirty}
          getValues={getFormValuesDynamically}
        />
      ),
    },
  ]

  const showSection = (section) => {
    return section.id === openFormSection
  }

  const formSections = sections.map((section) => {
    return (
      <Section key={section.id} open={showSection(section)} id={section.id}>
        <Header>
          {!isBusinessPath() && <Title>{t(section.title)}</Title>}
          {section.subtitle && (
            <Subtitle>
              <HtmlContent>{t(section.subtitle)}</HtmlContent>
            </Subtitle>
          )}
        </Header>
        <Content>{section.child}</Content>
      </Section>
    )
  })

  const onClose = () => {
    setshowErrorOnConnect(false)
  }
  const hasSubmitedForm = (bool) => {
    setHasSubmitedDataProtection(bool)
  }

  const onCloseEmailValidationModal = () => {
    setShowEmailValidationModal(false)
  }

  const goToEmail = () => {
    scrollToElement("input[name='customerData.contactData.email']")
    onCloseEmailValidationModal()
  }

  const onAdditionalProductSelection = (product: any) => {
    const { financialProduct } = storefrontData
    const updatedAdditionalProducts = financialProduct?.additionalProducts?.map(
      (p: any) =>
        p.code === product?.code
          ? {
              ...p,
              isSelected: !p?.isSelected,
            }
          : p
    )
    saveStorefrontData({
      ...storefrontData,
      financialProduct: {
        ...storefrontData?.financialProduct,
        additionalProducts: updatedAdditionalProducts,
      },
    })
  }

  return (
    <>
      {isloading && <Spinner center fullPage />}
      <EmailValidationModal
        transactionId={storefrontData?.transaction?.id}
        email={
          personalDetailsRef.current?.getCustomerData()?.contactData?.email
        }
        onClose={onCloseEmailValidationModal}
        onVerify={goToEmail}
        visible={showEmailValidationModal}
      />
      <Modal
        onClose={onClose}
        onClickOutside={onClose}
        title={t('error:heading')}
        shown={showErrorOnConnect}
      >
        <p> {t('error:text1')}</p>
        <Button testId="contactDetailsButton" onClick={onClose}>
          {t('form:btnContinue')}
        </Button>
      </Modal>
      <ContentSection pageWrap>
        <ContentSection.ComponentWrapper>
          <StorefrontShoppingCart foldout />
        </ContentSection.ComponentWrapper>
      </ContentSection>
      <ContentSection pageWrap className="u-pt-xxsmall u-pb-xxsmall">
        <legend className="c-form-heading">
          <strong className="c-form-heading__title">
            <span className="c-form-heading__title-text">
              {t('stepper:title')}
            </span>
          </strong>
        </legend>
        <p>
          {t(`stepper:${storefrontData?.salesChannel?.originSystem}:text1`)}
          <br />
          {t(`stepper:text2`)}
        </p>
        <InstructionComponent
          activeStep={2}
          originSystem={storefrontData?.salesChannel?.originSystem}
        />
      </ContentSection>
      <ContentSection pageWrap className="u-pt u-pb-small">
        <ContentSection.ComponentWrapper>
          {!editPage || (editPage && openFormSection) ? (
            <Group
              validSections={getFormSectionsValidSections()}
              alwaysOpen={sections
                .filter((item) => item.alwaysOpen)
                .map((item) => item.id)}
            >
              {formSections}
            </Group>
          ) : (
            ''
          )}
        </ContentSection.ComponentWrapper>
      </ContentSection>
      {showVehicleCondition ? (
        <ContentSection pageWrap className="u-pt-small u-pb-small">
          <ContentSection.ComponentWrapper>
            {isDealershop(storefrontData) ? (
              <VehicleConditionDealershop
                ref={vehicleConditionRef}
                storefrontData={storefrontData}
                setIsDirty={setIsDirty}
                setVehicleConditionValues={setVehicleConditionValues}
                hasSubmited={hasSubmitedDataProtection}
              />
            ) : (
              <VehicleconditionVafa
                ref={vehicleConditionRef}
                storefrontData={storefrontData}
                setIsDirty={setIsDirty}
                setVehicleConditionValues={setVehicleConditionValues}
                hasSubmited={hasSubmitedDataProtection}
              />
            )}
          </ContentSection.ComponentWrapper>
        </ContentSection>
      ) : (
        <></>
      )}
      {isDealershop(storefrontData) || isVtiGiS(storefrontData) ? (
        <AdditionalProducts onSelection={onAdditionalProductSelection} />
      ) : (
        <></>
      )}
      <ContentSection pageWrap className="u-pt-none u-pb-none">
        <ContentSection.ComponentWrapper>
          <DataProtection
            finishJourney={finishJourney}
            hasDefectsSelected={hasDefectsSelected}
            validateAllForms={validateAllForms}
            isBusiness={isBusinessPath()}
            isVtiGis={isVtiGiS(storefrontData)}
            setIsDirty={setIsDirty}
            hasSubmited={hasSubmitedForm}
            getValues={valuesPersonalForm}
          />
        </ContentSection.ComponentWrapper>
      </ContentSection>
      <ContentSection pageWrap className="u-pt-small u-pb-small">
        <ContentSection.ComponentWrapper>
          <div className="u-font-size-fs-2">
            <p>{t('form:dataProtection:footnotes1')}</p>
            <p>{t('form:dataProtection:footnotes2')}</p>
          </div>
        </ContentSection.ComponentWrapper>
      </ContentSection>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontId: getStoreFrontId(state),
    formData: getFormData(state),
    serviceAndInspection: getServiceAndInspection(state),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps, {
  saveFormData: saveFormDataAction,
  setDealerPaymentData,
  done,
  setTokenExpiresAtAction,
})(AppForm)
