export { contractSituations } from './contract-situations'
export { countries } from './countries'
export { phoneCountryCodes } from './phone-country-codes'
export { legalTypes } from './legal-types'
export { occupations } from './occupations'
export { residences } from './residences'
export { titles } from './titles'

export const salutationsList = [
  { value: 'MR', label: 'Herr' },
  { value: 'MRS', label: 'Frau' },
]
export const limitationsList = [
  { value: 'RESTRICTED', label: 'Befristet' },
  { value: 'UNRESTRICTED', label: 'Unbefristet' },
]
export const limitationsListBoolean = [
  { value: false, label: 'Befristet' },
  { value: true, label: 'Unbefristet' },
]
export const yesNo = [
  { value: true, label: 'Ja' },
  { value: false, label: 'Nein' },
]
export const agreedList = [
  { value: true, label: 'akzeptieren' },
  { value: false, label: 'ablehnen' },
]
export const maritalStatusesList = [
  {
    value: 'Solteiro(a)',
    label: 'Solteiro(a)',
  },
  {
    value: 'Casado(a)',
    label: 'Casado(a)',
  },
  {
    value: 'União de Facto',
    label: 'União de Facto',
  },
  {
    value: 'Não classificado',
    label: 'Não classificado',
  },
  {
    value: 'Separado(a)',
    label: 'Separado(a)',
  },

  {
    value: 'Divorciado(a)',
    label: 'Divorciado(a)',
  },
  {
    value: 'Viúvo(a)',
    label: 'Viúvo(a)',
  },
]
