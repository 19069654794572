import { Analytics } from '../../services/analytics'

const pageName = 'Summary'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
}

export const trackEmailValidationModal = () => {
  const specificTrackingData = {
    [Analytics.keys.viewChange()]: 'Email check',
    [Analytics.keys.eventType(0)]: 'pageview',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.linkInformation(0)]: 'Email check',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}

export const trackInvalidCode = () => {
  const specificTrackingData = {
    [Analytics.keys.viewChange()]: 'Email check - wrong code',
    [Analytics.keys.eventType(0)]: 'pageview',
    [Analytics.keys.eventAction(0)]: 'Success',
    [Analytics.keys.linkInformation(0)]: 'Email check - wrong code',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
