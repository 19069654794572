import { Analytics } from '.'

export const setBreakPoint = () => {
  let breakpoint = 'xxl'
  if (window.matchMedia('(max-width: 480px)').matches) {
    breakpoint = 'xs'
  } else if (window.matchMedia('(max-width: 720px)').matches) {
    breakpoint = 's'
  } else if (window.matchMedia('(max-width: 960px)').matches) {
    breakpoint = 'm'
  } else if (window.matchMedia('(max-width: 1280px)').matches) {
    breakpoint = 'l'
  } else if (window.matchMedia('(max-width: 1600px)').matches) {
    breakpoint = 'xl'
  }
  Analytics.addToDatalayer({
    'design.browserResolutionBreakpoint': breakpoint,
  })
}

export const setDataPrivacy = (dataPrivacy) => {
  Analytics.addToDatalayer({
    'dataPrivacyStatement.allowPostalAds': dataPrivacy.allowPostalAds,
    'dataPrivacyStatement.allowPhoneAds': dataPrivacy.allowPhoneAds,
    'dataPrivacyStatement.allowMailAds': dataPrivacy.allowMailAds,
  })
}
