import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph } from '@vwfs-bronson/bronson-react'
import { StorefrontShoppingCart } from '../../../components/ShoppingCart'

const Heading = (props) => {
  const { originSystem, stepper } = props
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className="u-text-center">
        <h1>
          <i className="c-icon c-icon--[semantic-success] u-text-success u-giga" />
        </h1>
        <h2>{t(`confirmation:${originSystem}:title`)}</h2>
        <h3>{t(`confirmation:${originSystem}:subtitle`)}</h3>
      </div>
      {i18n.exists(`confirmation:${originSystem}:text`) && (
        <Paragraph>{t(`confirmation:${originSystem}:text`)}</Paragraph>
      )}
      <div className="u-mt">
        <StorefrontShoppingCart foldout defaultOpen />
      </div>
      <div className="u-mt-large">
        {stepper && <div className="u-mt">{stepper}</div>}
      </div>
    </>
  )
}

export default Heading
