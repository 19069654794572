import { Analytics } from '../../services/analytics'

export const pageName = 'Technical error'

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: pageName,
}

export const trackPage = (errorCausingURL) => {
  Analytics.addToDatalayer({
    'error.errorCausingURL': errorCausingURL,
    'error.errorMessage': 'Technical error',
    'error.errorCode': 'Technical error',
  })
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: 'pageView',
    [Analytics.keys.eventAction(0)]: 'Success',
  }

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  })
}
