import React, { FunctionComponent, useState } from 'react'
import {
  Card,
  ButtonContainer,
  Button,
  Spinner,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { openTabUrl } from '../../../services/common/utils'
import generateProductRoute from '../../../services/api/productRoute'

export type AdditionalProductsSectionProps = {
  transactionId: string
  manufacturer: string
  serviceAndInspection: boolean
}

const AdditionalProductsSection: FunctionComponent<
  AdditionalProductsSectionProps
> = (props) => {
  const { t } = useTranslation()
  const { manufacturer, serviceAndInspection, transactionId } = props
  const [isLoading, setIsLoading] = useState(false)

  const goToServiceAndInspectionBaseLink = () => {
    const marketingLink = t(
      `marketing-cards:serviceAndInspection:link:${manufacturer}`
    )
    if (marketingLink) {
      openTabUrl(marketingLink)
    }
  }

  const handleRedirect = () => {
    setIsLoading(true)
    generateProductRoute(transactionId)
      .then((response) => {
        if (response?.data?.redirectUrl) {
          window.open(response?.data?.redirectUrl, '_blank')
        } else {
          goToServiceAndInspectionBaseLink()
        }
      })
      .catch(() => {
        goToServiceAndInspectionBaseLink()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading && <Spinner fullPage />}
      {serviceAndInspection && (
        <Card
          title={t(`confirmation:additionalProductsSection:title`)}
          className="u-text-left"
        >
          <Paragraph>
            <strong>{t(`confirmation:additionalProductsSection:subtitle`)}</strong>
          </Paragraph>
          <Paragraph>
            {t(`confirmation:additionalProductsSection:text`)}
          </Paragraph>
          <ButtonContainer nav right className="u-mt">
            <Button onClick={handleRedirect}>
              {t(`confirmation:additionalProductsSection:button:text`)}
            </Button>
          </ButtonContainer>
        </Card>
      )}
    </>
  )
}

export default AdditionalProductsSection
