import React from 'react'
import { ContentSection } from '@vwfs-bronson/bronson-react'
import Heading from './common/Heading'
import BasicSection from './common/BasicSection'
import { scrollToElement } from '../../services/common/form'
import InstructionComponent from '../AppPage/InstructionStepper'

const ConfirmationPageVAFA = ({ storefrontData }) => {
  const originSystem = storefrontData?.salesChannel?.originSystem

  React.useEffect(() => {
    scrollToElement('.c-header')
  }, [])

  return (
    <>
      <ContentSection pageWrap>
        <Heading
          originSystem={originSystem}
          stepper={
            <InstructionComponent activeStep={3} originSystem={originSystem} />
          }
        />
        <div className="u-mt-large">
          <BasicSection
            section="receiveAndFileDocuments"
            originSystem={originSystem}
            storefrontData={storefrontData}
          />
        </div>
      </ContentSection>
    </>
  )
}

export default ConfirmationPageVAFA
