/* eslint-disable no-nested-ternary */
import {
  isBusiness,
  isDealershop,
  isVtiGiS,
} from '../../../services/common/utils'

const getInitialValues = (formData, storefrontData) => {
  const vafaPrivate = {
    allowPhoneAds: false,
    allowMailAds: false,
    allowPersonalizedMarketing: false,
    allowMailCommunication: false,
    acceptVehicleDefects: false,
    acceptLimitationPeriodVafa: false,
    acceptPrecontractualInformationVafa: false,
    acceptVehicleOwnership: false,
    acceptLeasingTermination: false,
    acceptLeasingServices: false,
  }
  const vafaBusiness = {
    allowPhoneAds: false,
    allowMailAds: false,
    allowPersonalizedMarketing: false,
    allowMailCommunication: false,
    acceptPurchaseConditionsVafa: false,
    acceptVehicleCondition: false,
    acceptVehicleOwnership: false,
    acceptLeasingTermination: false,
    acceptLeasingServices: false,
  }
  const vti = {
    allowPhoneAds: false,
    allowMailAds: false,
    allowPersonalizedMarketing: false,
    allowMailCommunication: false,
    acceptVehicleDifferences: false,
    acceptLimitationPeriodVti: false,
    acceptPrecontractualInformationVti: false,
    acceptPurchaseConditionsVti: false,
    acceptInsuranceDocuments: false,
  }
  const dealershop = {
    acceptLimitationPeriodDealershop: false,
    acceptPrecontractualInformationDealershop: false,
    acceptSalesTerms: false,
  }

  return isVtiGiS(storefrontData)
    ? vti
    : isDealershop(storefrontData)
    ? dealershop
    : isBusiness(storefrontData)
    ? vafaBusiness
    : vafaPrivate
}

export default getInitialValues
