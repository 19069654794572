export interface ISessionData {
  transactionId?: string
  entryPointUrl?: string
  redirectUrl?: string
  token?: string
}

export class SessionDataHandling {
  /**
   * The initial data has been loaded, write localStorage and cookie information
   */
  public static saveSessionDataToStorage(sessionData: ISessionData) {
    try {
      window.sessionStorage.setItem('sessiondata', JSON.stringify(sessionData))
    } catch (ex) {
      console.error(ex)
    }
  }

  public static getSessionDataFromStorage(): ISessionData {
    try {
      const sessiondataString = window.sessionStorage.getItem('sessiondata')
      if (sessiondataString) {
        return JSON.parse(sessiondataString)
      }
      return {}
    } catch (ex) {
      console.error(ex)
      return {}
    }
  }
}
