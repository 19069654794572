import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  ContentSection,
  SectionHeading,
  Button,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux'
import { trackPage } from './tracking'
import {
  getRedirectUrl,
  getStorefrontData,
} from '../../services/redux/features/storefront.redux'

export type VehicleNotAvailablePageProps = {
  storefrontData: any
  redirectUrl: string
}

const VehicleNotAvailablePage = (props: VehicleNotAvailablePageProps) => {
  const { t } = useTranslation()
  const { storefrontData, redirectUrl } = props

  React.useEffect(() => {
    trackPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRedirectUrl = () => {
    return storefrontData?.salesChannel?.redirectUrl || redirectUrl
  }

  return (
    <ContentSection pageWrap>
      <SectionHeading level="2" testId="errorHeading">
        {t('vehicleNotAvailable:heading')}
      </SectionHeading>
      <Paragraph testId="errorText" className="u-text">
        {t('vehicleNotAvailable:text1')}
      </Paragraph>
      {getRedirectUrl() && (
        <Paragraph testId="tryAgain" className="u-text-center">
          <Button
            id="tryAgainButton"
            testId="tryAgainButton"
            type="submit"
            onClick={() => {
              window.location.href = getRedirectUrl()
            }}
          >
            {t('vehicleNotAvailable:button:text')}
          </Button>
        </Paragraph>
      )}
    </ContentSection>
  )
}
const mapStateToProps = (state) => {
  return {
    storefrontData: getStorefrontData(state),
    redirectUrl: getRedirectUrl(state),
  }
}
export default connect(mapStateToProps, null)(VehicleNotAvailablePage)
