export const legalTypes = [
  { value: 'Particular', label: 'Particular' },
  { value: 'Associação', label: 'Associação' },
  { value: 'Cooperativa', label: 'Cooperativa' },
  { value: 'Emp. Nome Indiv.', label: 'Emp. Nome Indiv.' },
  { value: 'Emp. Pública', label: 'Emp. Pública' },
  { value: 'Ent.Eq.Pess. Colecti', label: 'Ent.Eq.Pess. Colecti' },
  { value: 'Fundação', label: 'Fundação' },
  { value: 'Soc. Anónima (SA)', label: 'Soc. Anónima (SA)' },
  { value: 'Soc. Quotas (Lda)', label: 'Soc. Quotas (Lda)' },
  { value: 'Soc. Quotas Uni', label: 'Soc. Quotas Uni' },
]
