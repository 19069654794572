import React, { FunctionComponent } from 'react'
import { Layout, DescriptionList } from '@vwfs-bronson/bronson-react'
import TextsRender from './TextsRender'

export type DescListProps = {
  descriptionList: any
  descriptionListData: any
  templateValues?: any
}

const DescriptionListRender: FunctionComponent<DescListProps> = (props) => {
  const { descriptionList, descriptionListData, templateValues } = props

  return descriptionList ? (
    <Layout>
      {descriptionList?.prefix?.length > 0 && (
        <Layout.Item>
          <TextsRender
            texts={descriptionList?.prefix}
            templateValues={templateValues}
          />
        </Layout.Item>
      )}
      {descriptionList?.items?.length > 0 && (
        <Layout.Item>
          <Layout>
            <Layout.Item default="1/1">
              <DescriptionList split>
                {descriptionList?.items?.map((item: string, index: number) => (
                  <DescriptionList.Group key={item} termText={item}>
                    <DescriptionList.Detail>
                      {descriptionListData?.[index]}
                    </DescriptionList.Detail>
                  </DescriptionList.Group>
                ))}
              </DescriptionList>
            </Layout.Item>
          </Layout>
        </Layout.Item>
      )}
      {descriptionList?.suffix?.length > 0 && (
        <Layout.Item>
          <TextsRender
            texts={descriptionList?.suffix}
            templateValues={templateValues}
          />
        </Layout.Item>
      )}
    </Layout>
  ) : (
    <></>
  )
}

export default DescriptionListRender
