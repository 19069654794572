import React from 'react'
import {
  Header as BrHeader,
  ContextBar,
  Logo,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ContactPageModal from '../../pages/ContactPageModal'
import gebrauchtwagenLogo from '../../resources/images/logos/VWFS_SOB_Picto_Gebrauchtwagen_RGB_1row_left_aligned.svg'
import { isVWFS, isVtiGiS } from '../../services/common/utils'

export type HeaderProps = {}

const Header = (props) => {
  const { storefrontData } = props
  const [showContact, setShowContact] = React.useState(false)
  const { t } = useTranslation()

  return (
    <>
      <BrHeader
        testId="appHeader"
        staticHeader
        logo={
          <div style={{ display: 'flex', maxHeight: '70px' }}>
            <Logo testId="appHeaderLogo" breakpoint="720px" disableLink />
            {isVWFS() && isVtiGiS(storefrontData) && (
              <img src={gebrauchtwagenLogo} alt="" style={{ width: '50%' }} />
            )}
          </div>
        }
      >
        <ContextBar
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ContextBar.SiteNav>
            <ContextBar.SiteNav.Item.ContextItem
              contextBarIcon="semantic-chat"
              onClick={() => setShowContact(true)}
              iconLabel={t('contact:labelTitle')}
              normalLink
            />
          </ContextBar.SiteNav>
        </ContextBar>
      </BrHeader>

      <ContactPageModal
        testId="appContactPageModal"
        visible={showContact}
        onClose={() => setShowContact(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(Header)
