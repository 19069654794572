import axios from 'axios'

const { REACT_APP_API_GATEWAY } = process.env

export const sendTan = async (transactionId: string) => {
  return axios.post(
    `${REACT_APP_API_GATEWAY}/mail-validation/${transactionId}/send-tan`,
    {}
  )
}

export const tanValidation = async (tan: string, transactionId: string) => {
  return axios.post(
    `${REACT_APP_API_GATEWAY}/mail-validation/${transactionId}/validate-tan`,
    { tan }
  )
}
