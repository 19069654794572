import React from 'react'

import {
  FormField as CJFormField,
  ErrorMessage,
  Fieldset,
  InfoIcon,
} from '@vwfs-bronson/bronson-react'
import { Field } from 'formik'
import get from 'lodash/get'

interface Props {
  render: Function
  name: string
  labelElement?: string
  labelText?: string
  hint?: string
  infoIcon?: string
  notion?: boolean
  type?: string
  className?: string
  testId?: string
  handleBlur?: Function
  labelOuter?: boolean
  validate?: any
}

export const FormField = ({
  render,
  name,
  labelElement = 'div',
  labelText,
  hint,
  infoIcon,
  notion,
  type,
  className,
  testId,
  handleBlur,
  validate,
}: Props) => {
  return (
    <Fieldset.Row>
      <Field
        name={name}
        validate={validate}
        render={({ field, form }) => {
          return (
            <CJFormField
              onBlur={(e) => handleBlur && handleBlur(e)}
              testId={testId}
              className={className}
              labelElement={labelElement}
              labelText={
                ((
                  <>
                    {labelText}
                    {notion && '*'}
                    {infoIcon && (
                      <InfoIcon icon="semantic-info">{infoIcon}</InfoIcon>
                    )}
                  </>
                ) as unknown as string) || ''
              }
              type={
                type as
                  | 'input'
                  | 'select'
                  | 'textarea'
                  | 'checkbox'
                  | 'other'
                  | undefined
              }
              errorMessage={
                get(form.errors, name) && get(form.touched, name) ? (
                  <ErrorMessage>
                    {get(form.touched, name) && get(form.errors, name)}
                  </ErrorMessage>
                ) : (
                  ''
                )
              }
              hint={hint}
              noFloatingLabel
              staticLabel
            >
              {render({
                name,
                error: !!(get(form.touched, name)
                  ? get(form.errors, name)
                  : undefined),
                ...field,
              })}
            </CJFormField>
          )
        }}
      />
    </Fieldset.Row>
  )
}
