const getInitialValues = () => {
  return {
    customerData: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      address: {
        street: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        countryCode: 'DE',
      },
      contactData: {
        email: '',
        mobilePhoneCountryCode: '',
        mobilePhoneNumber: '',
      },
    },
    companyData: {
      entityLegalForm: '',
      name: '',
    },
  }
}

export default getInitialValues
