import axios from 'axios'
import * as actions from '../../redux/features/storefront.redux'
import * as sessionActions from '../../redux/features/session.redux'
import * as customActions from '../../redux/features/custom.redux'
import { store } from '../../redux'
import { history } from '../../../services/routing'
import routes from '../../../routes'
import { isDealershop, isVtiGiS } from '../../common/utils'

const { REACT_APP_API_GATEWAY } = process.env

export const getStorefrontData = (storefrontId: string) => {
  const url = `${REACT_APP_API_GATEWAY}/data/${storefrontId}`

  return axios.get(url, {
    validateStatus: (statusCode) => statusCode === 200,
  })
}
export const loadStorefrontDataToRedux = (cid: string) => {
  store.dispatch(actions.storeId(cid))
  return internalLoadStorefrontDataToRedux(cid)
}

const internalLoadStorefrontDataToRedux = (cid: string) => {
  store.dispatch(actions.pending())
  return getStorefrontData(cid)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response.status === 409) {
        store.dispatch(
          actions.setRedirectUrl(error?.response?.data?.payload?.redirectUrl)
        )
        history.pushPreservePath(routes.vehicleNotAvailablePage)
      } else {
        history.pushPreservePath(routes.errorPage)
      }
    })
    .then((data) => {
      store.dispatch(sessionActions.setTokenExpiresAt(data?.tokenExpiresAt))
      if (isVtiGiS(data) || isDealershop(data)) {
        store.dispatch(
          customActions.setServiceAndInspection(
            !!data?.custom?.serviceAndInspection
          )
        )
      }
      return store.dispatch(actions.initial(data))
    })
}
