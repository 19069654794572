import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ContentSection } from '@vwfs-bronson/bronson-react'

import Heading from './common/Heading'
import BasicSection from './common/BasicSection'
import { MarketingCards } from '../../components/MarketingCards'
import { DealerRole } from '../../models/enums'
import { formatPrice } from '../../services/common/format'
import { scrollToElement } from '../../services/common/form'
import InstructionComponent from '../AppPage/InstructionStepper'
import { getServiceAndInspection } from '../../services/redux/features/custom.redux'
import AdditionalProductsSection from './common/AdditionalProductsSection'

const ConfirmationPageVTIGIS = ({ serviceAndInspection, storefrontData }) => {
  const originSystem = storefrontData?.salesChannel?.originSystem
  const { t } = useTranslation()
  const deliveryType = storefrontData?.vehicleData?.delivery?.deliveryType

  const getDealerByRole = (role: string) => {
    return (
      !!storefrontData?.dealerData?.additionalDealers &&
      storefrontData?.dealerData?.additionalDealers?.find((dealer: any) =>
        [role].includes(dealer.dealerRole)
      )
    )
  }

  const dealer = getDealerByRole(DealerRole.PICKUP)

  const authorizeAndObtainVehicleTemplateValues = {
    companyName: dealer?.companyName,
    street: dealer?.addresses?.[0]?.street,
    houseNumber: dealer?.addresses?.[0]?.houseNumber,
    zipCode: dealer?.addresses?.[0].zipCode,
    city: dealer?.addresses?.[0].city,
  }

  React.useEffect(() => {
    scrollToElement('.c-header')
  }, [])

  const descriptionListValues = [
    formatPrice(storefrontData?.vehicleData?.purchasePriceAmount),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:paymentType`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:accountOwner`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:iban`
    ),
    t(
      `confirmation:${originSystem}:transferPurchasePrice:descriptionListValues:bic`
    ),
    storefrontData?.vehicleData?.vin,
  ]

  return (
    <>
      <ContentSection pageWrap>
        <Heading
          originSystem={originSystem}
          stepper={
            <InstructionComponent activeStep={3} originSystem={originSystem} />
          }
        />
        <div className="u-mt-large">
          <BasicSection
            section="receiveAndFileDocuments"
            originSystem={originSystem}
            storefrontData={storefrontData}
          />
        </div>

        <div className="u-mt-large">
          <BasicSection
            section="transferPurchasePrice"
            originSystem={originSystem}
            storefrontData={storefrontData}
            descriptionListValues={descriptionListValues}
          />
        </div>

        <div className="u-mt-large">
          <BasicSection
            section="authorizeAndObtainVehicle"
            originSystem={originSystem}
            storefrontData={storefrontData}
            deliveryType={deliveryType}
            templateValues={authorizeAndObtainVehicleTemplateValues}
          />
        </div>

        {serviceAndInspection && (
          <div className="u-mt-large">
            <AdditionalProductsSection
              transactionId={storefrontData?.transaction?.id}
              manufacturer={storefrontData?.vehicleData?.model?.manufacturer}
              serviceAndInspection={serviceAndInspection}
            />
          </div>
        )}

        {!serviceAndInspection && (
          <div className="u-mt-large">
            <MarketingCards
              manufacturer={storefrontData?.vehicleData?.model?.manufacturer}
              hideMotorInsurance
              storefrontData={storefrontData}
            />
          </div>
        )}
      </ContentSection>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    serviceAndInspection: getServiceAndInspection(state),
  }
}

export default connect(mapStateToProps, null)(ConfirmationPageVTIGIS)
