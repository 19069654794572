export default {
  landingPage: '/prefill',
  editPage: '/edit',
  errorPage: '/error',
  expiredSession: '/expired-session',
  confirmationPage: '/confirmation',
  licenses: '/licenses',
  vehicleNotAvailablePage: '/unavailable-vehicle',
  dataProtectionInfo: '/data-protection',
  transactionsApproval: '/transactions/:id/approval/:acceptanceId',
  transactionsRefusal: '/transactions/:id/refusal/:acceptanceId',
  transactionsConfirmed: '/transactions-confirmed',
  transactionsCanceled: '/transactions-canceled',
  transactionsError: '/transactions-error',
}
