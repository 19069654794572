import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  InfoIcon,
  ResponsiveImage,
} from '@vwfs-bronson/bronson-react'
import { isENVKVNew, notFalsy } from '../../services/common/utils'
import { TestProcedureType } from '../../models/enums'
import ENVKVShoppingCartCaption from '../ENVKV/ENVKVShoppingCartCaption'
import { store } from '../../services/redux'
import { history } from '../../services/routing'
import routes from '../../routes'

type Props = {
  vehicleData?: any
}

const ShoppingCartImageFooter: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('form:shoppingCart')
  const suffix = Array.from(
    t('form:shoppingCart:imageFooter.suffix.texts', {
      returnObjects: true,
    }) as Array<string>
  )

  const parseEfficiencyClass = (efficiencyClass) => {
    switch (efficiencyClass) {
      case 'A_PLUS_PLUS_PLUS':
        return 'A+++'
      case 'A_PLUS_PLUS':
        return 'A++'
      case 'A_PLUS':
        return 'A+'
      case 'A':
        return 'A'
      case 'B':
        return 'B'
      case 'C':
        return 'C'
      case 'D':
        return 'D'
      case 'F':
        return 'F'
      case 'G':
        return 'G'
      default:
        return efficiencyClass
    }
  }

  const { vehicleData } = props
  const model = vehicleData?.model
  const economics = model?.economics
  const wltp = economics?.find(
    (item: any) => item.testProcedureType === TestProcedureType.WLTP
  )
  const nedc = economics?.find(
    (item) => item.testProcedureType === TestProcedureType.NEDC
  )
  const consumptionsValueWLTP = wltp?.consumptions?.[0]?.combined?.value
  const consumptionsValueNEDC = nedc?.consumptions?.[0]?.combined?.value
  const consumptionsUnitWLTP =
    nedc?.consumptions?.[0]?.combined?.unit ||
    t('form:shoppingCart:imageFooter:defaultConsumptionsUnit')
  const consumptionsUnitNEDC =
    nedc?.consumptions?.[0]?.combined?.unit ||
    t('form:shoppingCart:imageFooter:defaultConsumptionsUnit')
  const emissionsValueWLTP = wltp?.emissions?.[0]?.combined?.value
  const emissionsValueNEDC = nedc?.emissions?.[0]?.combined?.value
  const emissionsUnitWLTP =
    wltp?.emissions?.[0]?.combined?.unit ||
    t('form:shoppingCart:imageFooter:defaultEmissionsUnit')
  const emissionsUnitNEDC =
    nedc?.emissions?.[0]?.combined?.unit ||
    t('form:shoppingCart:imageFooter:defaultEmissionsUnit')
  const efficiencyClass = economics?.[0]?.efficiencyClass
    ? parseEfficiencyClass(economics?.[0]?.efficiencyClass)
    : undefined

  return isENVKVNew(vehicleData) ? (
    <ENVKVShoppingCartCaption
      onError={() => history.push(routes.errorPage)}
      transactionId={store.getState()?.storefront?.data?.transaction?.id}
      vehicleData={vehicleData}
    />
  ) : (
    <>
      <Paragraph>
        {t('form:shoppingCart:imageFooter:WLTP:consumptionCombined')}
        :&nbsp;
        {`${notFalsy(consumptionsValueWLTP) ? consumptionsValueWLTP : '-'} ${
          consumptionsUnitWLTP || ''
        } `}
        <InfoIcon icon="semantic-info">
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('form:shoppingCart:imageFooter:emissionsTooltip'),
            }}
          />
        </InfoIcon>
        <br />
        {t('form:shoppingCart:imageFooter:WLTP:co2')}:&nbsp;
        {`${notFalsy(emissionsValueWLTP) ? emissionsValueWLTP : '-'} ${
          emissionsUnitWLTP || ''
        } `}
        <InfoIcon icon="semantic-info">
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('form:shoppingCart:imageFooter:emissionsTooltip'),
            }}
          />
        </InfoIcon>
        <br />
      </Paragraph>

      <Paragraph>
        {t('form:shoppingCart:imageFooter:NEDC:consumptionCombined')}
        :&nbsp;
        {`${notFalsy(consumptionsValueNEDC) ? consumptionsValueNEDC : '-'} ${
          consumptionsUnitNEDC || ''
        } `}
        <InfoIcon icon="semantic-info">
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('form:shoppingCart:imageFooter:emissionsTooltip'),
            }}
          />
        </InfoIcon>
        <br />
        {t('form:shoppingCart:imageFooter:NEDC:co2')}:&nbsp;
        {`${notFalsy(emissionsValueNEDC) ? emissionsValueNEDC : '-'} ${
          emissionsUnitNEDC || ''
        } `}
        <InfoIcon icon="semantic-info">
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('form:shoppingCart:imageFooter:emissionsTooltip'),
            }}
          />
        </InfoIcon>
        <br />
        {t('form:shoppingCart:imageFooter:NEDC:energyEfficiency')}
        :&nbsp;
        {notFalsy(efficiencyClass) ? efficiencyClass : '-'}{' '}
        <InfoIcon icon="semantic-info">
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('form:shoppingCart:imageFooter:emissionsTooltip'),
            }}
          />
        </InfoIcon>
      </Paragraph>

      <Paragraph>
        <ResponsiveImage
          src={`${process.env.PUBLIC_URL}/images/EfficiencyList.svg`}
          height="280vw"
        />
      </Paragraph>

      {suffix?.length > 0 &&
        suffix?.map((text: any) => (
          <Paragraph key={text} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
    </>
  )
}

export default ShoppingCartImageFooter
