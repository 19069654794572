import React, { FunctionComponent } from 'react'
import { Output } from '../../../Text'

export type TextsRenderProps = {
  texts: string[]
  templateValues?: any
}

const TextsRender: FunctionComponent<TextsRenderProps> = (props) => {
  const { texts, templateValues } = props

  return texts?.length > 0 ? (
    <>
      {texts?.map((text: string) => (
        <span key={text}>
          {Output.markdown(Output.templateWithValues(text, templateValues))}
        </span>
      ))}
    </>
  ) : (
    <></>
  )
}

export default TextsRender
