export const FETCH_STOREFRONT_PENDING = 'storefront//FETCH_STOREFRONT_PENDING'
export const FETCH_STOREFRONT_DONE = 'storefront//FETCH_STOREFRONT_DONE'
export const FETCH_STOREFRONT_ERROR = 'storefront//FETCH_STOREFRONT_ERROR'
export const FETCH_STOREFRONT_STORE_ID = 'storefront//FETCH_STOREFRONT_STORE_ID'
export const SET_REDIRECT_URL = 'storefront//SET_REDIRECT_URL'
export const SET_DEALER_PAYMENT_DATA = 'storefront//SET_DEALER_PAYMENT_DATA'
interface PendingAction {
  type: typeof FETCH_STOREFRONT_PENDING
  payload: {}
}

interface DoneAction {
  type: typeof FETCH_STOREFRONT_DONE
  payload: any
}

interface ErrorAction {
  type: typeof FETCH_STOREFRONT_ERROR
  payload: any
}

interface StoreIdAction {
  type: typeof FETCH_STOREFRONT_STORE_ID
  payload: any
}

interface SetRedirectUrlAction {
  type: typeof SET_REDIRECT_URL
  payload: any
}

interface SetDealerPaymentAction {
  type: typeof SET_DEALER_PAYMENT_DATA
  payload: any
}

export type StoreFrontActionTypes =
  | PendingAction
  | DoneAction
  | ErrorAction
  | StoreIdAction
  | SetRedirectUrlAction
  | SetDealerPaymentAction
