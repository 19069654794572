import axios from 'axios'

const { REACT_APP_API_GATEWAY } = process.env

export const getConfirmationData = async (transactionId: string) => {
  return axios
    .get(`${REACT_APP_API_GATEWAY}/confirmation/${transactionId}`)
    .then((res) => {
      return res.data
    })
}

export const confirmationApprove = async (
  transactionId: string,
  acceptanceId: string
) => {
  return axios.post(
    `${REACT_APP_API_GATEWAY}/confirmation/${transactionId}/approve/${acceptanceId}/start`,
    {}
  )
}

export const confirmationApproveStatus = (
  transactionId: string,
  acceptanceId: string
) => {
  return axios
    .get(
      `${REACT_APP_API_GATEWAY}/confirmation/${transactionId}/approve/${acceptanceId}/status`,
      {}
    )
    .then((response) => response.data)
}

export const confirmationRefuse = async (
  transactionId: string,
  acceptanceId: string
) => {
  return axios.post(
    `${REACT_APP_API_GATEWAY}/confirmation/${transactionId}/refuse/${acceptanceId}`,
    {}
  )
}
