/* eslint-disable no-console */
/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS_SCRIPT environment variable we are able
 * to either export the real or mocked implementation, so that from the
 * application point of view nothing changes and it can continue calling the
 * analytics logic as per usuall.
 */
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import merge from 'lodash/merge'
import get from 'lodash/get'

const timeToLoad = 6000

const mock = {
  pageName: '',

  trackedFirst: false,
  queuedList: [],
  index: 0,

  default: {},
  datalayer: {},
  pageBottom: function pageBottom() {},

  setDefaults: function setDefaults(obj) {
    Object.assign(this.default, obj)
    this.setCommonValues()
  },

  getFromDatalayer: function getFromDatalayer(key) {
    return get(this.datalayer, key)
  },

  addToDatalayer: function addToDatalayer(additions) {
    Object.keys(additions).forEach((key) => {
      set(this.datalayer, key, additions[key])
    })
  },

  setCommonValues: function setCommonValues() {
    this.addToDatalayer({
      ...(process.env.REACT_APP_WEBSITE_BRAND
        ? { [this.keys.brand()]: process.env.REACT_APP_WEBSITE_BRAND }
        : {}),
    })
  },

  setTrackingData: function setTrackingData(additions) {
    const clonedDefaultDatalayer = cloneDeep(this.default)
    const trackingData = {}
    Object.keys(additions).forEach((key) => {
      set(trackingData, key, additions[key])
    })
    window.du_digitalData = merge(
      {},
      clonedDefaultDatalayer,
      this.datalayer,
      trackingData
    )
  },

  trackViewChange: function viewChange() {
    this.track('viewChange')
  },

  addToQueuedList: function addToQueuedList() {
    this.queuedList.push(this.index)
    this.index += 1
  },

  removeFromQueuedList: function removeFromQueuedList() {
    if (this.queuedList.length) {
      this.queuedList.shift()
      this.trackedFirst = true
    }
  },

  delayTrack: function delayTrack(track) {
    this.addToQueuedList()
    setTimeout(() => {
      track()
      this.removeFromQueuedList()
    }, timeToLoad)
  },

  doTrack: function doTrack(data, action) {
    const trackJob = () => {
      this.setTrackingData(data)
      this.track(action)
    }
    if (this.trackedFirst) {
      if (this.queuedList.length) {
        this.delayTrack(trackJob)
      } else {
        trackJob()
      }
    } else {
      this.delayTrack(trackJob)
    }
  },

  trackPage: function page(data) {
    setTimeout(() => {
      const pageName = get(data, this.keys.pageName())
      if (pageName) {
        this.addToDatalayer({
          [this.keys.pageName()]: pageName,
        })
      }
      this.doTrack(data, 'page')
    }, 1000)
  },

  trackInteraction: function interaction(data) {
    this.doTrack(data, 'interaction')
  },

  trackDownload: function interaction(data) {
    this.doTrack(data, 'download')
  },

  log: function log(action) {
    console.log(
      `%cTrack ${action}\n %cpageName: %c${get(
        window.du_digitalData,
        this.keys.pageName()
      )}\n %cviewChange: %c${get(
        window.du_digitalData,
        this.keys.viewChange()
      )}\n %cevent0.type: %c${get(
        window.du_digitalData,
        this.keys.eventType(0)
      )}\n %cevent0.action: %c${get(
        window.du_digitalData,
        this.keys.eventAction(0)
      )}\n\n %cDatalayer:`,
      'color:blue;font-size:1rem;font-weight:bold',
      'color:blue;font-size:0.9rem',
      'color:black',
      'color:blue;font-size:0.9rem',
      'color:black',
      'color:blue;font-size:0.9rem',
      'color:black',
      'color:blue;font-size:0.9rem',
      'color:black',
      'color:blue;font-size:0.9rem',
      window.du_digitalData
    )
  },

  track: function track(action) {
    if (typeof action !== 'string') {
      console.warn('Legacy tracking code detected')
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    if (!implementation.isLive || window.__CJ_DEBUG_ANALYTICS) {
      this.log(action)
    }
    // eslint-disable-next-line
    window._satellite &&
      window._satellite.track &&
      window._satellite.track(action)
  },

  trackError: function trackError(url, errorCode, errorMessage) {
    const defaultErrorData = {
      pageName: 'Error',
      viewChange: 'Error',
      eventType: 'pageView',
      eventAction: 'Success',
    }
    const analyticsData = {
      401: {
        ...defaultErrorData,
        eventType1: 'Login',
        eventAction1: 'Unauthorized',
        errorMessage: 'Unauthorized',
      },
      403: {
        ...defaultErrorData,
        eventType1: 'Access',
        eventAction1: 'Denied',
        errorMessage: 'Access denied',
      },
      404: {
        ...defaultErrorData,
        eventType1: 'Error',
        eventAction1: 'Unauthorized',
        errorMessage: 'Page not found',
      },
      500: {
        pageName: 'Technical error',
        viewChange: 'Technical error',
        eventType: 'Error',
        eventAction: 'Technical error',
      },
    }
    this.trackPage({
      [this.keys.pageName()]:
        analyticsData[`${errorCode}`]?.pageName || 'Technical error',
      [this.keys.viewChange()]:
        analyticsData[`${errorCode}`]?.pageName || 'Technical error',
      [this.keys.eventType(0)]:
        analyticsData[`${errorCode}`]?.eventType || 'Error',
      [this.keys.eventAction(0)]:
        analyticsData[`${errorCode}`]?.eventAction || 'Technical Error',
      ...(analyticsData[`${errorCode}`]?.eventType1 && {
        [this.keys.eventType(1)]: analyticsData[`${errorCode}`]?.eventType1,
      }),
      ...(analyticsData[`${errorCode}`]?.eventAction1 && {
        [this.keys.eventAction(1)]: analyticsData[`${errorCode}`]?.eventAction1,
      }),
      [this.keys.errorCode()]: errorCode,
      [this.keys.errorMessage()]:
        analyticsData[`${errorCode}`]?.errorMessage || errorMessage,
      [this.keys.errorCausingURL()]: url,
    })
  },

  keys: {
    brand: () => 'core.attributes.brand',
    context: () => 'core.attributes.context',
    formType: () => 'form.type',
    formName: () => 'form.name',
    errorFields: () => 'form.errorFields',
    lastTouchedField: () => 'form.lastTouchedField',
    pageName: () => 'core.pageInfo.pageName',
    faqText: () => 'core.attributes.faqText',
    loginStatus: () => 'customerData.loginStatus',
    productVariants: () => 'core.category.productVariants',
    viewChange: () => 'core.attributes.viewChange',
    eventType: (eventCount) => `event[${eventCount}].eventInfo.eventType`,
    eventAction: (eventCount) => `event[${eventCount}].eventInfo.eventAction`,
    linkInformation: (eventCount) =>
      `event[${eventCount}].eventInfo.linkInformation`,
    errorCode: () => 'error.errorCode',
    errorMessage: () => 'error.errorMessage',
    errorCausingURL: () => 'error.errorCausingURL',
    dealerDataCompanyId: () => 'dealerData.companyId',
    dealerDataCompanyName: () => 'dealerData.companyName',
    dealerDataAddressStreet: () => 'dealerData.address.street',
    dealerDataZipCode: () => 'dealerData.address.zipCode',
    dealerDataCity: () => 'dealerData.address.city',
    dealerDataState: () => 'dealerData.address.state',
    addons: (eventCount) => `product[0].attributes.addOns[${eventCount}].name`,
    loggedInUserGroup: () => 'customerData.loggedInUserGroup',
  },
}

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom() {
    window._satellite &&
      window._satellite.track &&
      window._satellite.pageBottom() // eslint-disable-line
  },
}

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS_SCRIPT) {
  implementation = real
}

export { implementation as Analytics }
