import React from 'react'
import { ContentSection, InstructionStepper } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

interface InstructionComponentProps {
  activeStep: number
  originSystem: string
}

const InstructionComponent = (props: InstructionComponentProps) => {
  const { t } = useTranslation()
  const { activeStep, originSystem } = props
  return (
    <ContentSection.ComponentWrapper>
      <InstructionStepper>
        {Object.entries(
          t(`stepper:${originSystem}:steps`, {
            returnObjects: true,
            defaultValue: t('stepper:default:steps', { returnObjects: true }),
          })
        )?.map(([key, value]: any, index) => (
          <InstructionStepper.Step
            key={key}
            state={index < activeStep ? 'is-active' : undefined}
            icon={value?.icon}
            title={value?.title}
          >
            {value?.text}
          </InstructionStepper.Step>
        ))}
      </InstructionStepper>
    </ContentSection.ComponentWrapper>
  )
}

export default InstructionComponent
