import axios from 'axios'
const { REACT_APP_API_GATEWAY } = process.env

const generateProductRoute = async (transactionId: string) => {
  const url = `${REACT_APP_API_GATEWAY}/product-route/${transactionId}/generate`
  const data = {}
  return axios.post(url, data).then(
    (response) => response,
    (err) => err
  )
}
export default generateProductRoute
