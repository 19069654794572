import React from 'react'
import { Formik, Form, FastField, ErrorMessage } from 'formik'
import {
  Fieldset,
  Layout,
  Select,
  Textarea,
  Input,
  Card,
  ContentSection,
  ErrorMessage as BronsonErrorMessage,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import * as Yup from 'yup'
import { FormField } from '../../../components/FormField'
import { CheckboxField } from '../../../components/CheckboxField'
import { VehicleDefects } from '../../../models/enums'
import { connect } from 'react-redux'

const VehicleConditionVafa = (props, ref) => {
  const {
    hasSubmited,
    storefrontData,
    setVehicleConditionValues,
    setIsDirty: setIsDirtyParent,
  } = props
  const [isDirty, setIsDirty] = React.useState(false)
  const formState = React.useRef(undefined as any)

  let submitFunction
  React.useImperativeHandle(ref, () => ({
    submit: () => {
      submitFunction()
    },
    isValid: () => {
      return formState.current.isValid
    },
    validate: () => {
      return formState.current.isValid
    },
  }))

  React.useEffect(() => {
    setIsDirtyParent(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  React.useEffect(() => {
    if (hasSubmited) {
      setTouchedForm(formState.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubmited])

  const onChangeForm = async (e, formProps) => {
    const { target } = e
    const { name } = target
    if (name) {
      const value = target.type === 'checkbox' ? target.checked : target.value
      await formProps.setFieldValue(name, value)
      await formProps.setFieldTouched(name)
      await setVehicleConditionValues({ ...formProps.values, [name]: value })
    }
  }
  const setTouchedForm = (formProps) => {
    Object.keys(formProps.values).forEach((field) => {
      formProps.setFieldTouched(field)
    })
  }

  const ValidationSchema = Yup.object().shape({
    odometer: Yup.string()
      .matches(/^\d+$/, i18n.t('validation:invalidCharacter'))
      .required(i18n.t('validation:required')),
    condition: Yup.string()
      .required(i18n.t('validation:required'))
      .oneOf(
        [VehicleDefects.NO_DEFECTS, VehicleDefects.DEFECTS],
        i18n.t('validation:required')
      ),
    defectsDescription: Yup.string().when('condition', {
      is: (condition) => condition === VehicleDefects.DEFECTS,
      then: Yup.string().required(i18n.t('validation:required')),
    }),
    acceptVehicleDefects: Yup.boolean().when('condition', {
      is: (condition) => condition === VehicleDefects.DEFECTS,
      then: Yup.boolean()
        .required(i18n.t('validation:required'))
        .oneOf([true], i18n.t('validation:required')),
    }),
    acceptLimitationPeriodVafa: Yup.boolean()
      .required(i18n.t('validation:required'))
      .oneOf([true], i18n.t('validation:required')),
  })
  const { t } = useTranslation()
  return (
    <Formik
      innerRef={formState}
      initialValues={{
        odometer: storefrontData?.vehicleData?.odometer,
        acceptVehicleDefects: false,
        acceptLimitationPeriodVafa: false,
        condition: undefined,
        defectsDescription: '',
      }}
      onSubmit={() => {}}
      validationSchema={ValidationSchema}
      validateOnMount
      render={(formProps) => {
        setIsDirty(formProps.dirty)
        submitFunction = formProps.submitForm
        return (
          <Form
            id="vehicle-condition-form"
            onChange={(e) => onChangeForm(e, formProps)}
          >
            <Card className="u-text-left">
              <Layout>
                <Layout.Item default="1/1" s="1/1">
                  <legend className="c-form-heading">
                    <strong className="c-form-heading__title">
                      <span className="c-form-heading__title-text">
                        {t('form:vehicleCondition:titleKmtsSection')}
                      </span>
                    </strong>
                  </legend>
                </Layout.Item>
                <Layout.Item default="1/4" s="1/1">
                  <Fieldset>
                    <Fieldset.Row>
                      <FormField
                        testId="odometer"
                        type="input"
                        name="odometer"
                        labelText={t('form:vehicleCondition:odometer')}
                        render={(fieldProps) => (
                          <Input
                            maxLength="20"
                            {...fieldProps}
                            value={storefrontData?.vehicleData?.odometer}
                            readOnly
                          />
                        )}
                      />
                    </Fieldset.Row>
                  </Fieldset>
                </Layout.Item>
                <Layout.Item default="1/1" s="1/1">
                  <legend className="c-form-heading">
                    <strong className="c-form-heading__title">
                      <span className="c-form-heading__title-text">
                        {t('form:vehicleCondition:title')}
                      </span>
                    </strong>
                  </legend>
                </Layout.Item>

                <Fieldset>
                  <Layout.Item default="1/2" s="1/1">
                    <Fieldset.Row>
                      <FormField
                        testId="condition"
                        type="select"
                        name="condition"
                        labelText={t('form:vehicleCondition:vehicleCondition')}
                        render={(fieldProps) => (
                          <Select values={formProps.values} {...fieldProps}>
                            <Select.Item value="">
                              {t(
                                'form:vehicleCondition:conditionLabels:choose'
                              )}
                            </Select.Item>
                            <Select.Item value={VehicleDefects.NO_DEFECTS}>
                              {t(
                                'form:vehicleCondition:conditionLabels:hasNotDefects'
                              )}
                            </Select.Item>
                            <Select.Item value={VehicleDefects.DEFECTS}>
                              {t(
                                'form:vehicleCondition:conditionLabels:hasDefects'
                              )}
                            </Select.Item>
                          </Select>
                        )}
                      />
                    </Fieldset.Row>
                  </Layout.Item>
                  {formProps.values.condition === VehicleDefects.DEFECTS && (
                    <>
                      <Layout.Item default="1/2" s="1/1">
                        <Fieldset.Row>
                          <FormField
                            testId="defectsDescription"
                            type="input"
                            name="defectsDescription"
                            labelText={t(
                              'form:vehicleCondition:vehicleConditionDescription'
                            )}
                            infoIcon={t(
                              'form:vehicleCondition:vehicleConditionDescription_tooltip'
                            )}
                            render={(fieldProps) => (
                              <Textarea rows="4" {...fieldProps} />
                            )}
                          />
                        </Fieldset.Row>
                      </Layout.Item>
                      <Layout.Item default="1/1" s="1/1">
                        <Fieldset.Row className="u-mt-large">
                          <FastField
                            name="acceptVehicleDefects"
                            label={
                              <span>
                                {t(
                                  'form:vehicleCondition:checkboxDeviationAccept'
                                )}
                              </span>
                            }
                            component={CheckboxField}
                          />
                          <ErrorMessage
                            name="acceptVehicleDefects"
                            component={BronsonErrorMessage}
                          />
                        </Fieldset.Row>
                      </Layout.Item>
                    </>
                  )}
                  <ContentSection>
                    <Layout.Item default="1/1" s="1/1">
                      <Fieldset.Row>
                        <h4 className="u-h4">
                          {t('form:vehicleCondition:titleLimitationPeriod')}
                        </h4>
                      </Fieldset.Row>
                    </Layout.Item>
                    <Layout.Item default="1/1" s="1/1">
                      <Paragraph
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'form:vehicleCondition:fullLimitationPeriodDescription'
                          ),
                        }}
                      />
                    </Layout.Item>

                    <Layout.Item default="1/1" s="1/1">
                      <Fieldset.Row className="u-mt-large">
                        <FastField
                          name="acceptLimitationPeriodVafa"
                          label={t(
                            'form:vehicleCondition:checkboxAcceptLimitationPeriod'
                          )}
                          component={CheckboxField}
                        />
                        <ErrorMessage
                          name="acceptLimitationPeriodVafa"
                          component={BronsonErrorMessage}
                        />
                      </Fieldset.Row>
                    </Layout.Item>
                  </ContentSection>
                </Fieldset>
              </Layout>
            </Card>
          </Form>
        )
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {}
}

// export default VehicleCondition
export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(React.forwardRef(VehicleConditionVafa))
