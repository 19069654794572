import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { DefaultLayout } from '../Layout'
import AppForm from '../../pages/AppPage'
import ErrorPage from '../../pages/ErrorPage'
import ConfirmationPage from '../../pages/ConfirmationPage'
import { getBrandName } from '../../config'
import routes from '../../routes'
import ThirdPartyLicensePage from '../../pages/LicensesPage/ThirdPartyLicensePage'
import VehicleNotAvailablePage from '../../pages/VehicleNotAvailablePage'
import WithStorefront from '../../services/api/storefront/with-storefront'
import { NavigationBlockedModal } from '../../components/NavigationBlockedModal/index'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import DataProtectionInfoPage from '../../pages/DataProtectionInfoPage'
import {
  getTokenExpiresAt,
  setTokenExpiresAt as SetTokenExpiresAtAction,
} from '../../services/redux/features/session.redux'
import { ExpiredSessionModal } from '../../pages/ExpiredSession/ExpiredSessionModal'
import { expiredSessionLogic } from '../../pages/ExpiredSession/ExpiredSessionLogic'
import { ExpiredSessionPage } from '../../pages/ExpiredSession/ExpiredSessionPage'
import { history } from '../../services/routing'
import { saveSesionData } from '../../services/common/utils'
import TransactionsApproval from '../../pages/Transactions/TransactionsApproval'
import TransactionsConfirmed from '../../pages/Transactions/TransactionsConfirmed'
import TransactionsCanceled from '../../pages/Transactions/TransactionsCanceled'
import TransactionsRefusal from '../../pages/Transactions/TransactionsRefusal'
import TransactionsErrorPage from '../../pages/Transactions/TransactionsErrorPage'

export type RoutesProps = {
  storefrontId: string
  identificationId: string
  getPageFile: any
}

const Routes = ({
  storefrontId,
  entryUrl,
  storefrontData,
  tokenExpiresAt,
  SetTokenExpiresAtAction: setTokenExpiresAt,
}) => {
  const [showExpiredSessionModal, setShowExpiredSessionModal] = useState(false)
  useEffect(() => {
    if (tokenExpiresAt) {
      expiredSessionLogic(tokenExpiresAt, () =>
        setShowExpiredSessionModal(true)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpiresAt])
  return (
    <>
      <ExpiredSessionModal
        shown={showExpiredSessionModal}
        onConfirm={(data: any) => {
          setShowExpiredSessionModal(false)
          saveSesionData({
            transactionId: storefrontId,
            token: data?.data?.token,
          })
          setTokenExpiresAt(data?.data?.tokenExpiresAt)
        }}
        onClose={() => {
          setShowExpiredSessionModal(false)
          history.push(routes.expiredSession)
        }}
        transactionId={storefrontId}
      />
      <Route component={NavigationBlockedModal} />
      <Switch>
        <Route
          exact
          path={routes.editPage}
          render={(editProps) => {
            return (
              <DefaultLayout>
                <AppForm {...editProps} storefrontId={storefrontId} editPage />
              </DefaultLayout>
            )
          }}
        />
        <Route
          path={routes.errorPage}
          render={() => {
            return (
              <DefaultLayout>
                <ErrorPage entryUrl={entryUrl} />
              </DefaultLayout>
            )
          }}
        />
        <Route
          path={routes.vehicleNotAvailablePage}
          render={() => (
            <DefaultLayout>
              <VehicleNotAvailablePage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.expiredSession}
          render={() => (
            <DefaultLayout>
              <ExpiredSessionPage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.confirmationPage}
          render={() => (
            <DefaultLayout>
              <ConfirmationPage
                originSystem={get(storefrontData, 'salesChannel.originSystem')}
              />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.transactionsApproval}
          render={(routeProps) => (
            <DefaultLayout>
              <TransactionsApproval {...routeProps} />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.transactionsConfirmed}
          render={() => (
            <DefaultLayout>
              <TransactionsConfirmed />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.transactionsRefusal}
          render={(routeProps) => (
            <DefaultLayout>
              <TransactionsRefusal {...routeProps} />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.transactionsCanceled}
          render={() => (
            <DefaultLayout>
              <TransactionsCanceled />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.transactionsError}
          render={() => (
            <DefaultLayout>
              <TransactionsErrorPage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.licenses}
          render={() => (
            <DefaultLayout>
              <ThirdPartyLicensePage />
            </DefaultLayout>
          )}
        />
        <Route
          path={routes.dataProtectionInfo}
          render={() => (
            <DefaultLayout>
              <DataProtectionInfoPage />
            </DefaultLayout>
          )}
        />
        <WithStorefront storefrontId={storefrontId}>
          <Route
            path={routes.landingPage}
            render={(routeProps) => (
              <DefaultLayout>
                <AppForm
                  {...routeProps}
                  storefrontId={storefrontId}
                  brand={getBrandName()}
                />
              </DefaultLayout>
            )}
          />
        </WithStorefront>

        <Redirect from="*" to="/prefill" />
      </Switch>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    storefrontData: getStorefrontData(state),
    tokenExpiresAt: getTokenExpiresAt(state),
  }
}
export default connect(mapStateToProps, { SetTokenExpiresAtAction })(Routes)
