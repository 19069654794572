import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { i18n as i18nDefault } from '../../resources/base/content'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      default: i18nDefault,
    },
    lng: 'default',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

/* Request brand-specific resources and override (using deep) the
 * resources for specific brand. Make sure to chain those to avoid
 * race conditions.
 */
/*
 export default brandName => {
  return import(`../../resources/${brandName}/content/index.js`)
  .then(module => {
    ;[
      'validation',
      'contact',
      'app',
      'confirmation',
      'wizard',
      'error',
      'application-sent',
      'loading',
      'signature-error',
      'form',
      'personal-details',
      'contact-details',
      'delivery-details',
      'summary',
    ].forEach(ns => {
      i18n.addResourceBundle('en', ns, module.i18n[ns], true, true)
    })
}
*/
