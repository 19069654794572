import React, { FunctionComponent } from 'react'
import { Footer as BrFooter, BackToTop } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export type FooterProps = {
  storefrontData: any
}

const Footer: FunctionComponent<FooterProps> = ({ storefrontData }) => {
  const { t } = useTranslation()

  const buildCopyrightText = () => {
    return t(`footer:${storefrontData?.salesChannel?.originSystem}:copyright`, {
      companyName: storefrontData?.dealerData?.companyName,
      defaultValue: t(`footer:default:copyright`),
    })
  }

  const renderItems = () => {
    const items = t(
      `footer:${storefrontData?.salesChannel?.originSystem}:links`,
      {
        returnObjects: true,
        defaultValue: t(`footer:default:links`, { returnObjects: true }),
      }
    ) as any
    return (
      <>
        {items?.length &&
          items?.map((currentPage) => (
            <BrFooter.ModuleMetaNav.Item
              key={currentPage.url ? currentPage.url : undefined}
              href={currentPage.url ? currentPage.url : undefined}
              linkProps={{
                target: currentPage.target,
                normalLink: true,
              }}
            >
              {currentPage.name}
            </BrFooter.ModuleMetaNav.Item>
          ))}
      </>
    )
  }
  return (
    <>
      <BackToTop>{t('app:footer:backToTopLabel')}</BackToTop>

      <BrFooter>
        <BrFooter.ModuleMetaNav
          copyrightSign=""
          copyrightText={buildCopyrightText() as unknown as string}
        >
          {renderItems()}
        </BrFooter.ModuleMetaNav>
      </BrFooter>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, null)(Footer)
