export const DEFAULT_DATALAYER = {
  core: {
    /* Please put here the stage (DEV, INT, CONS, PROD) on which this site is */
    stagingEnvironment: null,

    dataLayerVersion: '2.10',

    pageInfo: {
      /*
            The values for pagename are defined in tracking specification
            document.
            */
      pageName: null,

      /*
            The device type the website is intended for.
            Type: Enum
            Mobile phone
            Tablet
            Desktop
            all
            */
      intendedCustomerDeviceType: 'all',

      /*
            Version or release of the site. Constant value of your choice
            which identifies best this current application version.
            */
      version: 'Rel 6.0',

      /*
            Release date of this site as a constant value. Format YYYY-MM-DD
            */
      releaseDate: '2023-06-19',

      /*
            Current display language.
            Type: Enum
            ISO-639-2; lower case two letter code
            Hint: Visitor can change the language while his visit (e.g. in
            Switzerland).
            */
      language: 'de',

      /*
            Type: Enum
            The country name of the market this site is serving
            (e.g. DE: W&I -> DE, UK: S&I -> UK).
            ISO-3166-1-alpha-2; upper case two letter code
            */
      market: 'DE',

      /*
            Type: Enum
            Name of responsible department (DU, local-DE, local-UK)
            */
      publisher: 'DU',
    },
    category: {
      /*
            Main category of this site.
            Type: Enum
            Banking
            Charge & fuel
            Customer portal
            Digital renewal
            Finance and lease
            Financing
            Fleet
            fob
            Insurance
            Leasing
            Local customer portal
            Local portal
            Local public site
            NBW
            Rental
            Service
            */
      primaryCategory: 'Direct payment',

      /*
            Please further describe your journey/site *if necessary*. The
            product owner can choose a unique name.
            Examples:
            Champions Club
            Short term insurance
            Service and inspection
            */
      secondaryCategory: null,

      /*
            If your journey splits in sub processes, standalone, checkout site,
            app, ...
            Type: Enum
            app
            checkoutSite
            standalone
            */
      siteType: 'checkoutSite',

      inventoryType: 'Asset-based products',
      contractType: 'New',

      /*
            Classification of the result which should be achieved by the journey
            Type: Enum
            Lead
            Online contract
            */
      maturityLevel: 'Online contract',
    },
    attributes: {
      /*
            Type: Enum
            customer-facing-product-journey
            customer-facing-journey
            dealer-facing-product-journey
            dealer-facing-journey
            after-sales-journey
            */
      journeyType: 'customer-facing-product-journey',

      /*
            If your page contains expandables, which once expanded do not
            change the URL. Example: On page personal data, there are
            expandables for address, account details, ... The values are
            specified in the corresponding specification document.
            */
      viewChange: null,

      /*
            Refers to the branding of the site.

            Never include design versions here: This should not be changed to
            vw6 when a new design is released. The brand is still vw.
            vwcv refers to VW Commercial Vehicles. BFFâ€™s data model supplies
            vwn, but we expect vwcv.

            @Developers: Please fill in the correct value here based on the
            site's
            branding

            Type: Enum
            Audi
            Bentley
            Cupra
            Ducati
            Lamborghini
            MAN
            Porsche
            Scania
            Seat
            Skoda
            VW
            VWCV
            VWFS
            */
      brand: 'VWFS',
    },
  },
  /*
    In case of any error situations (mainly standard errors like 401, 404, ...
    and in context of forms) an error code (if available) and the error message
    (if possible a shortened version) are placed here. If available the causing
    URL (e.g. the wrong URL in case of 404, the referring URL in case 401, ...)
    should be placed here, too.
    */
  error: {
    // Error code of application exceptions or caught errors
    errorCode: null,

    // Error message for caught errors with available message
    errorMessage: null,

    // Full URL which caused the error
    errorCausingURL: null,
  },
  /*
    This structure is filled as soon as the user provides information
    regarding any product.

    Please note that this structure is an array structure. If the customer can
    bundle his contract (i.e. get a leasing contract, a service and maintenance
    package, and a tire package, this array should have three entries. Same is
    e.g. in Customer portal if customer has more than one product.
    */
  product: [
    {
      /*
        The main category of the current contract.
        Type: Enum
        Finance
        Insurance
        Leasing
        Service
        Other
        */
      category: 'Other products',

      /*
        The name of the product from the given list. If you cannot find a
        suitable entry for the product sold in the journey, please reach out
        to the web analytics team. Please do not add any products to this list
        on your own.

        Type: Enum
        AutoCredit
        CarSharingProtection
        ChargeAndFuel
        ClassicCredit
        Credit
        CreditProtectionInsurance
        LeasingRateInsurance
        DigitalRenewal
        ExtendedWarranty
        FinanceLease
        GuaranteedAssetProtection
        MotorInsurance
        OperateLease
        RentalCarProtection
        ServiceInspection
        ServiceLimitedMaintenance
        TestDriveProtection
        ThirdPartyProtection
        UsedCarWarranty
        TirePackage
        */
      name: 'Cash purchase',

      attributes: {
        /*
            Specifies the type of complete sales process

            Type: Enum
            Hybrid sales
            Online sales
            Partner sales
            */
        typeOfSale: 'Online sales',

        /*
            E.g. monthly payment for insurance or leasing contract or an
            installment. Please provide exact (float) value
            Type: Float
            The currency is â‚¬.
            */
        recurringPayment: null,

        paymentFrequency: 'single payment',
        currency: 'EUR',

        /*
            Planned duration of contract; please use value -1 if indefinite
            Type: Integer
            */
        duration: null,

        /*
            Unit for planned duration of contract.
            Type: Enum
            WEEK
            TWO-WEEKS
            MONTH
            TWO-MONTHS
            QUARTER
            SIX-MONTHS
            YEAR
            */
        durationUnit: null,

        /*
            The start date of contract in focus.
            Type: Date (YYYY-MM-DD)
            */
        startDateOfContract: null,

        /*
            The agreed mileage in contract. If the contract duration is
            shorter than a year use the agreed amount.
            Type: Integer
        */
        yearlyMileage: null,

        /* The unit of yearlyMileage, excessMileage or undrivenMileage.
            Should always be KILOMETERS in the european region.
            Type: Enum
            KILOMETERS,
            MILES
            */
        mileageUnit: 'KILOMETERS',

        /*
            The type of payment.

            Type: Enum

            Automatic Bank Transfer
            Credit Card
            Direct Deposit
            E-Wallet
            Mobile Payment
            */
        paymentType: 'Direct Deposit',

        /*
            The final amount customer has to pay to fulfill the contract.
            The currency is â‚¬.
            Type: Float
            */
        contractAmount: null,
        addOns: [
          {
            name: null,
            additionalInformation: null,
          },
        ],
      },
      // Aligned with BFF data model!
      vehicleModel: [
        {
          /*
        The manufacturer of the current vehicle.
        Type: Enum
        audi
        bentley
        cupra
        ducati
        man
        porsche
        scania
        seat
        skoda
        vw
        vwcv
        vwfs
        */
          manufacturer: null,

          /*
        The main name of the current car.

        Type: string, for example
        A3
        Kodiaq
        XC60
        Auris
        Golf
        */
          name: null,

          /*
        The first subcategory of the current car in manufacturers product
        program.
        Type: string
        For example
        GTI
        */
          modelVariation: null,

          /*
        The year when the model has been released.
        Type: string
        Format: YYYY
        */
          year: null,

          /*
        The second subcategory of the current car in manufacturers product
        program.
        Type: string
        For example
        Sportline
        Momentum
        Sportback
        Performance
        */
          modelLine: null,

          /*
        Long description of the vehicle model
        Type: String
        Example:
        Golf GTI Performance
        A3 Sportback 1.5 TFSI Black Edition
        */
          descriptionLong: null,

          /*
        For tracking of hybrid sales, the sales id which is handed over
        Type: String
        */
          salesID: null,

          /*
        Describes the body type of the vehicle.
        Type: Enum
        CONVERTIBLE
        COUPE
        CROSSOVER
        CABRIO
        LUXURY CAR
        SEDAN
        SPORTS CAR
        SUV
        TRUCK
        VAN
        WAGON
        */
          bodyType: null,

          /*
        Vehicle class. E.g. for leasing the class the vehicle belongs to.
        */
          category: null,

          /*
        If it is a vehicle with a special warranty (Das Weltauto,
        TradePort) then true, otherwise false
        Type: Boolean
        */
          certifiedPreOwned: null,

          /*
        The current mileage of the car
        Original value: 15388 km
        Data layer value: 15388
        Type: Integer
        */
          currentMileage: null,

          /*
        The unit of the current mileage of the car
        Type: Enum
        KILOMETERS,
        MILES
        */
          currentMileageUnit: null,

          /*
        The date when the vehicle has been registered first (In german
        "Tag der Erstzulassung").

        This value is of special importance to be tracked correctly as it
        serves as a measure for customer loyalty. Tracking this value
        correctly is of high importance for our management.
        Type: String
        Format: YYYY-MM-DD
        */
          initialRegistrationDate: null,

          /*
        The base price of current vehicle
        Type: Integer
        Unit local currency
        Original value: 15388 PLN
        Data layer value: 15388
        */
          basePrice_localCurrency: null,

          /*
        Local currency
        ISO-4217 alphabetic code upper case letters
        Example: PLN
        */
          currency: null,

          /*
        The final vehicle price (local currency). The unit is not part of
        the value.
        Type: Integer
        Unit local currency
        Original value: 15388 PLN
        Data layer value: 15388
        */
          endPrice_localCurrency: null,

          /*
        Manufacturer key number if available (typically in Germany HSN)
        Type: String
        Value for Volkswagen:
        0603
        Value for Volvo:
        1263
        Value for BMW:
        0005
        */
          manufacturerCode: null,

          /*
        Model code number if available (typically in Germany TSN)
        Type: String
        Value for VW Golf Cabriolet 1.2 TSI:
        BEK
        Value for Volvo XC 60 2.4 D AWD:
        BAR
        */
          typeCode: null,

          /*
        The base color of the current vehicle

        Enum:
        Black
        Blue
        Brown
        Green
        Grey
        Red
        Violet
        White
        Yellow
        */
          colorExterior: null,

          /*
        The type of usage

        Enum:
        Private
        Business
        Both
        */
          typeOfUse: null,

          /*
        For example, for digital renewal, please insert the model
        description of the priorModel (please refer to vehicle variable
        descriptionLong).
        */
          priorModel: null,

          /*
        The equity of the prior model
        Type: Float
        */
          equity: null,

          /*
        The condition of the vehicle.
        E.g.
        New car
        Used car
        */
          condition: null,

          engine: {
            /*
            The engines fuel type of the vehicle

            Enum:
            CNG
            Diesel
            Electric
            Hybrid
            Hydrogen
            LPG
            Petrol
            */
            fuelType: null,

            /*
            The emission value of the engine.
            Format: <nnn> g/km
            */
            emission: null,

            /*
            Transmission; e.g.
            6-speed manual
            7-speed automatic
            */
            transmission: null,

            /*
            The engines power in both units: PS and kW. E.g.
            115 PS (84 kW)
            */
            enginePower: null,
          },
        },
      ],
    },
  ],
  // Aligned with BFF data model!
  dealerData: {
    /*
        The 5-digit company id which uniquely identifies the car dealer within
        the VW ecosystem.
        Type: string
        */
    companyId: null,

    /*
        The company name of the car dealer.
        Type: string
        */
    companyName: null,

    address: {
      // Dealers address
      street: null,
      // The postal code of dealerâ€™s address.
      zipCode: null,
      // The city of the address.
      city: null,
      /* The region the dealer is located. In Germany this would be the
            federal state.
            */
      state: null,
    },
  },
  form: {
    /* The main subject of the form. Search forms are handled by the search
        element of this data layer.
        */
    type: null,

    /* The meaningful descriptive name of the form in context of the current
        site.
        */
    name: null,

    /* If required content of form fields are included here. Syntax:
    <shortened field name1>: <value1> | <shortened field name2>: <value2>
    Field names should be in English.
    */
    fieldValues: null,

    // If the form is aborted the meaningful name of last touched field.
    lastTouchedField: null,

    /* In case of user input errors, a semicolon-/whitespace separated list of
        meaningful shortened names of all erroneous fields.
        Example:
        Plate; Zip
        */
    errorFields: null,
  },
  design: {
    /*
        For responsive design: Thresholds when design changes. The values are
        the Bronson breakpoints:
        xxl (browser tab width >= 1920 px)
        xl  (browser tab width >= 1600 px)
        l   (browser tab width >= 1280 px)
        m   (browser tab width >=  960 px)
        s   (browser tab width >=  720 px)
        xs  (browser tab width >=  480 px)
        Type: string
        */
    browserResolutionBreakpoint: null,
  },
  customerData: {
    /*
        Indicates whether the user is currently logged in or not
        Type: Boolean
        */
    loginStatus: 'false',

    /*
        The user group the customer belongs to. Specified values are:
        - private
        - business
        */
    loggedInUserGroup: null,

    /*
        The year the customer was born.

        Format: YYYY
        */
    yearOfBirth: null,

    /*
        The gender of the customer
        Type: Enum
        MALE
        FEMALE
        */
    gender: null,
    address: {
      /* If the customer provides multiple addresses, we expect the zip
            code of latest address here.
            */
      zipCode: null,
    },
  },
  // Aligned with BFF data model!
  dataPrivacyStatement: {
    /*
        If the customer allows a contact via postal the value is true otherwise
        false.
        Type: Boolean
        */
    allowPostalAds: null,

    /*
        If the customer allows a contact via phone the value is true otherwise
        false.Type: Boolean
        */
    allowPhoneAds: null,

    /*
        If the customer allows a contact via email the value is true otherwise
        false.
        Type: Boolean
        */
    allowMailAds: null,
  },
  /*
    Together with the PO or BA we map our standard events to your journey and
    provide you a specification document with an annotated version of the
    journey. Please fill use the values specified in this document.
    */
  event: [
    {
      eventInfo: {
        /*
            The type of event which occurred. This information describes the
            context in which the current event occurred. The values are
            specified in the corresponding specification document.
            */
        eventType: null,

        /*
            The event which occurred. The values are specified in the
            corresponding specification document.
            */
        eventAction: null,

        /*
            If a link or button triggers this event, please provide the target
            url of this link.
            */
        eventTargetURL: null,

        /*
            If additional information in context of an interaction element
            is needed. The values are specified in the corresponding
            specification document.
            */

        linkInformation: null,
      },
    },
  ],
}
