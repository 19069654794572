import { Modal, Paragraph, Spinner } from '@vwfs-bronson/bronson-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  confirmationApprove,
  confirmationApproveStatus,
  getConfirmationData,
} from '../../../services/api/confirmation'
import * as actions from '../../../services/redux/features/storefront.redux'
import { store } from '../../../services/redux'
import { history } from '../../../services/routing'
import routes from '../../../routes'
import { isCorrectEntryState } from '../transactions.utils'
import { BasicPollingStatus } from '../../../models/enums'

export type TransactionsApprovalPageProps = {
  storefrontData: any
  match: any
}

const TransactionsApprovalPage: FunctionComponent<
  TransactionsApprovalPageProps
> = (props) => {
  const { storefrontData, match } = props
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const getData = async () => {
    setIsReady(false)
    try {
      const data = await getConfirmationData(match?.params?.id)
      if (!isCorrectEntryState(data)) {
        return history.push(routes.transactionsError)
      }
      store.dispatch(actions.storeId(match?.params?.id))
      store.dispatch(actions.initial(data))
    } catch {
      history.push(routes.errorPage)
    } finally {
      setIsReady(true)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOriginSystem = () => storefrontData?.salesChannel?.originSystem

  const checkPolling = () => {
    const finishPoll = () => {
      clearInterval(timer)
      clearTimeout(timeoutTimer)
      setIsLoading(false)
    }

    const checkStatus = (data: any) => {
      const status = data?.status
      switch (status) {
        case BasicPollingStatus.SUCCESS:
          finishPoll()
          history.push(routes.transactionsConfirmed)
          break
        case BasicPollingStatus.ERROR:
          finishPoll()
          history.push(routes.errorPage)
          break
        default:
          break
      }
    }

    const executePoll = () => {
      if (!isPending) {
        isPending = true
        confirmationApproveStatus(
          match?.params?.id,
          match?.params?.acceptanceId
        )
          .then(
            (data: any) => {
              checkStatus(data)
            },
            () => {
              finishPoll()
              history.push(routes.errorPage)
            }
          )
          .finally(() => {
            isPending = false
          })
      }
    }

    const timeoutReached = () => {
      finishPoll()
    }

    let isPending = false
    const TIMEOUT = 250000
    const WAIT_TIME = 3000
    const timeoutTimer = setTimeout(() => timeoutReached(), TIMEOUT)
    const timer = setInterval(executePoll, WAIT_TIME)
  }

  const onConfirm = async () => {
    setIsLoading(true)
    try {
      await confirmationApprove(match?.params?.id, match?.params?.acceptanceId)
      checkPolling()
    } catch {
      history.push(routes.errorPage)
    }
  }

  return (
    <>
      {(isLoading || !isReady) && <Spinner center fullPage />}
      {isReady && (
        <Modal
          center
          title={t(`transactions:${getOriginSystem()}:approval:title`, {
            defaultValue: t(`transactions:default:approval:title`),
          })}
          shown={true}
          hideCloseButton
          buttonConfirmText={t(
            `transactions:${getOriginSystem()}:approval:confirmButton`,
            {
              defaultValue: t(`transactions:default:approval:confirmButton`),
            }
          )}
          onConfirm={onConfirm}
        >
          <Paragraph className="u-pt u-pb">
            {t(`transactions:${getOriginSystem()}:approval:subtitle`, {
              defaultValue: t(`transactions:default:approval:subtitle`),
            })}
          </Paragraph>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storefrontData: state.storefront.data,
  }
}

export default connect(mapStateToProps, {})(TransactionsApprovalPage)
